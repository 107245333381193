import type { Scalars } from 'generated/types'

import { pipe } from 'remeda'

const BASE_URL = `${process.env.NEXT_PUBLIC_API_PREVIEW_BASE_URL ?? ''}`

const TYPE_URL_PREFIX_MAP: Record<string, string> = {
  album: `${BASE_URL}/audio/`,
  artist: `${BASE_URL}/artist/`,
  epoch: `${BASE_URL}/epoch/`,
  genre: `${BASE_URL}/genre/`,
  group: `${BASE_URL}/group/`,
  live_concert: `${BASE_URL}/video/`,
  partner: `${BASE_URL}/partner/`,
  video: `${BASE_URL}/video/`,
  vod_concert: `${BASE_URL}/video/`,
}

// TODO: this relies on the id structure, eventually we should use `__typename`
export const getWebsiteLink = (id: Scalars['ID']['output']): string | undefined =>
  pipe(
    id,
    (input) => {
      const parts = input.split('_')
      parts.pop()
      return parts.join('_')
    },
    (type: string | undefined) => {
      if (type === undefined) {
        return undefined
      }
      const url_prefix = TYPE_URL_PREFIX_MAP[type]

      if (url_prefix === undefined) {
        return undefined
      }

      return `${url_prefix}${id}`
    },
  )

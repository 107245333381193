import type { SetValueConfig } from 'react-hook-form'

import { useCallback } from 'react'
import { useController, useFormContext } from 'react-hook-form'

type UseFieldProps = {
  defaultValue?: unknown
  helpText?: string
  name: string
}

export enum FieldValidationState {
  error = 'error',
  initial = 'initial',
  valid = 'valid',
}

export const getValidationState = (isTouched: boolean, hasError: boolean, isSubmitted: boolean) => {
  if (!isTouched && !isSubmitted) {
    return FieldValidationState.initial
  }

  return hasError ? FieldValidationState.error : FieldValidationState.valid
}

/**
 * Hook that extracts the current field value and state plus additional setters and information from the form state
 *
 * This is modeled after the `useField`-Hook that we used from formik before the migration, in order to provide
 * a largely consistent API to what the developers were used before
 */
export function useField<TValueType = unknown>({ defaultValue, helpText, name }: UseFieldProps) {
  // aggregate information from different RHF hooks
  const { field, fieldState, formState } = useController({
    defaultValue,
    name,
  })
  const { clearErrors, setError, setValue } = useFormContext()
  const { error, isTouched } = fieldState
  const { isSubmitted } = formState

  /**
   * Scoped imperative setters: RHF does not provide a field-hook so all its imperative state setters
   * like `setValue` are for the complete form and take the path to the field as first parameter.
   *
   * We scope these functions to the passed field name.
   *
   * ! This might not work as expected in all cases as RHF has some rules for these setters due to its performance
   * ! orientation that we cannot completely abstract away with this implementation. This might fail for deeply
   * ! nested fields eg. `form.cuePoints.4.title.en`. If you encounter this, get the top-level set-value from the
   * ! form context in your component and target the value directly:
   * @example
   * const { setValue } = useField({name: cuePoints.4.title }) // not like this
   * setValue({ en: "en value", de: "", ja: ""})
   *
   * const { setValue } = useAugmentedFormContext() // like this
   * setValue("cuePoints.4.title.en", "en value")
   * setValue("cuePoints.4.title.de", "")
   * setValue("cuePoints.4.title.ja", "")
   */
  const setFieldValue = useCallback(
    (value: unknown, options?: SetValueConfig) => setValue(name, value, options),
    [setValue, name],
  )
  const setFieldError = useCallback((message: string) => setError(name, { message, type: 'manual' }), [setError, name])
  const clearError = useCallback(() => clearErrors(name), [clearErrors, name])

  // shared functionality and abstracted implementation of naming conventions
  const validationState = getValidationState(isTouched, !!error, isSubmitted)
  // ? `root`-property is relevant for top-level error-validations
  const description = validationState === FieldValidationState.error ? error?.message ?? error?.root?.message : helpText
  const labelId = `labels-${name.toLowerCase()}`
  const descriptionId = `describes-${name.toLowerCase()}`

  return {
    clearError,
    // * helptext or error-message for the field depending on validationState
    description,
    // * the `id` attribute for the error, helptext-element for the field
    descriptionId,

    // * potential error as extracted from fieldState
    error,

    field: {
      ...field,
      ['aria-describedby']: descriptionId,

      ['aria-errormessage']: validationState === FieldValidationState.error ? descriptionId : undefined,
      // * additional html-attributes that should be automatically set on the field
      ['aria-labelledby']: labelId,
      // ! override field.value in order to ensure that it has the correct ts type
      value: field.value as TValueType,
    },

    fieldState,

    formState,
    //*  the `id`-attribute for the label-element of the field
    labelId,

    setError: setFieldError,
    // * imperative state setters
    setValue: setFieldValue,
    // * the fields resolved validation state depending on potential server-side- or client-side-errors
    validationState,
  }
}

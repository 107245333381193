import type { ComponentPropsWithRef } from 'react'

import { CheckIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid'
import { clsx } from 'clsx'
import { forwardRef } from 'react'

import { FieldValidationState } from 'hooks/use-field'

import { convertForHelper, forms } from './_tokens'

export type TextInputElementProps = {
  validationState: FieldValidationState
} & ComponentPropsWithRef<'input'>

const classes = convertForHelper({
  base: [forms.base, forms.baseRadius, 'block', 'w-full'],
  container: 'relative rounded-md shadow-sm',
  error: [forms.error, 'pr-10'],
  readonly: [forms.readonly],
  untouched: forms.untouched,
  valid: [forms.valid, 'pr-10'],
})

export const TextInputElement = forwardRef<HTMLInputElement, TextInputElementProps>(function TextInputElement(
  {
    className,
    id,
    name,
    onChange,
    placeholder,
    readOnly = false,
    required,
    validationState,
    value,
    ...props
  }: TextInputElementProps,
  reference,
) {
  return (
    <div className={clsx(classes.container, className)}>
      <input
        aria-invalid={validationState === FieldValidationState.error}
        aria-required={required ?? false}
        className={clsx(classes.base, {
          [classes.error]: validationState === FieldValidationState.error,
          [classes.readonly]: readOnly === true,
          [classes.untouched]: validationState === FieldValidationState.initial,
          [classes.valid]: readOnly === false && validationState === FieldValidationState.valid,
        })}
        id={id}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        readOnly={readOnly}
        ref={reference}
        type="text"
        value={value}
        {...props}
      />
      {readOnly === false && validationState === FieldValidationState.error && (
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
        </div>
      )}
      {readOnly === false && validationState === FieldValidationState.valid && (
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <CheckIcon className="h-5 w-5 text-gray-400" />
        </div>
      )}
    </div>
  )
})

import * as Types from '../types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export const PublicationErrorFieldsFragmentDoc = gql`
  fragment PublicationErrorFields on PublicationError {
    reason
  }
`
export const UniqueIsrcPublicationErrorFieldsFragmentDoc = gql`
  fragment UniqueIsrcPublicationErrorFields on UniqueIsrcPublicationError {
    reason
    isrc
    conflictingNodes {
      id
      type
    }
  }
`
export const ValidationErrorFieldsFragmentDoc = gql`
  fragment ValidationErrorFields on ValidationError {
    field
    validation
  }
`
export const AdminApiErrorFragmentDoc = gql`
  fragment AdminApiError on ApiError {
    __typename
    message
    path
    ... on PublicationError {
      ...PublicationErrorFields
    }
    ... on UniqueIsrcPublicationError {
      ...UniqueIsrcPublicationErrorFields
    }
    ... on ValidationError {
      ...ValidationErrorFields
    }
    ... on AuthenticationError {
      code
    }
  }
  ${PublicationErrorFieldsFragmentDoc}
  ${UniqueIsrcPublicationErrorFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`
export const PictureInfoFragmentDoc = gql`
  fragment PictureInfo on Picture {
    id
    type
    url
  }
`
export const ReferencedPicturesFragmentDoc = gql`
  fragment ReferencedPictures on PictureConnection {
    edges {
      status
      node {
        ...PictureInfo
      }
    }
  }
  ${PictureInfoFragmentDoc}
`
export const ReferencedPartnersFragmentDoc = gql`
  fragment ReferencedPartners on CorePartnerConnection {
    edges {
      status
      node {
        id
        name {
          en
        }
        type
      }
    }
  }
`
export const ReferencedGroupsFragmentDoc = gql`
  fragment ReferencedGroups on CoreGroupConnection {
    edges {
      status
      node {
        id
        name {
          en
        }
      }
    }
  }
`
export const ReferencedArtistsFragmentDoc = gql`
  fragment ReferencedArtists on CoreArtistConnection {
    edges {
      status
      node {
        id
        name {
          en
        }
      }
    }
  }
`
export const ReferencedArtistsWithRoleFragmentDoc = gql`
  fragment ReferencedArtistsWithRole on CoreArtistWithRoleConnection {
    edges {
      status
      node {
        id
        name {
          en
        }
      }
      role {
        id
        type
        subtype
      }
    }
  }
`
export const RoleInfoFragmentDoc = gql`
  fragment RoleInfo on CoreRole {
    id
    type
    subtype
    displayName
  }
`
export const ReferencedRolesFragmentDoc = gql`
  fragment ReferencedRoles on CoreRoleConnection {
    edges {
      status
      node {
        ...RoleInfo
      }
    }
  }
  ${RoleInfoFragmentDoc}
`
export const ReferencedListRolesFragmentDoc = gql`
  fragment ReferencedListRoles on CoreRoleListConnection {
    edges {
      node {
        ...RoleInfo
      }
    }
  }
  ${RoleInfoFragmentDoc}
`
export const AllLocalesFragmentDoc = gql`
  fragment AllLocales on TranslatedString {
    de
    en
    ja
  }
`
export const ReferencedAlbumsFragmentDoc = gql`
  fragment ReferencedAlbums on AlbumConnection {
    edges {
      status
      node {
        id
        title {
          ...AllLocales
        }
      }
    }
  }
  ${AllLocalesFragmentDoc}
`
export const PageInfoFragmentDoc = gql`
  fragment PageInfo on PageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`
export const AdminUserInfoFragmentDoc = gql`
  fragment AdminUserInfo on AdminUser {
    id
    name
    role
    email
    isActive
  }
`
export const DraftEditorFragmentDoc = gql`
  fragment DraftEditor on AdminUser {
    id
    name
    email
  }
`
export const StreamInfoFragmentDoc = gql`
  fragment StreamInfo on Stream {
    id
    url
    mvVideoUrl
  }
`
export const LoginAdminUserDocument = gql`
  mutation loginAdminUser($input: LoginAdminUserInput) {
    login(input: $input) {
      errors {
        ...AdminApiError
      }
      result {
        token
      }
    }
  }
  ${AdminApiErrorFragmentDoc}
`
export type LoginAdminUserMutationFn = Apollo.MutationFunction<
  Types.LoginAdminUserMutation,
  Types.LoginAdminUserMutationVariables
>

/**
 * __useLoginAdminUserMutation__
 *
 * To run a mutation, you first call `useLoginAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginAdminUserMutation, { data, loading, error }] = useLoginAdminUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginAdminUserMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.LoginAdminUserMutation, Types.LoginAdminUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.LoginAdminUserMutation, Types.LoginAdminUserMutationVariables>(
    LoginAdminUserDocument,
    options,
  )
}
export type LoginAdminUserMutationHookResult = ReturnType<typeof useLoginAdminUserMutation>
export type LoginAdminUserMutationResult = Apollo.MutationResult<Types.LoginAdminUserMutation>
export type LoginAdminUserMutationOptions = Apollo.BaseMutationOptions<
  Types.LoginAdminUserMutation,
  Types.LoginAdminUserMutationVariables
>
export const LogoutAdminUserDocument = gql`
  mutation logoutAdminUser {
    logout {
      errors {
        ...AdminApiError
      }
      result {
        loggedOut
      }
    }
  }
  ${AdminApiErrorFragmentDoc}
`
export type LogoutAdminUserMutationFn = Apollo.MutationFunction<
  Types.LogoutAdminUserMutation,
  Types.LogoutAdminUserMutationVariables
>

/**
 * __useLogoutAdminUserMutation__
 *
 * To run a mutation, you first call `useLogoutAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutAdminUserMutation, { data, loading, error }] = useLogoutAdminUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutAdminUserMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.LogoutAdminUserMutation, Types.LogoutAdminUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.LogoutAdminUserMutation, Types.LogoutAdminUserMutationVariables>(
    LogoutAdminUserDocument,
    options,
  )
}
export type LogoutAdminUserMutationHookResult = ReturnType<typeof useLogoutAdminUserMutation>
export type LogoutAdminUserMutationResult = Apollo.MutationResult<Types.LogoutAdminUserMutation>
export type LogoutAdminUserMutationOptions = Apollo.BaseMutationOptions<
  Types.LogoutAdminUserMutation,
  Types.LogoutAdminUserMutationVariables
>
export const AdminUserProfileDocument = gql`
  query adminUserProfile {
    profile {
      ...AdminUserInfo
    }
  }
  ${AdminUserInfoFragmentDoc}
`

/**
 * __useAdminUserProfileQuery__
 *
 * To run a query within a React component, call `useAdminUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminUserProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.AdminUserProfileQuery, Types.AdminUserProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.AdminUserProfileQuery, Types.AdminUserProfileQueryVariables>(
    AdminUserProfileDocument,
    options,
  )
}
export function useAdminUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminUserProfileQuery, Types.AdminUserProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.AdminUserProfileQuery, Types.AdminUserProfileQueryVariables>(
    AdminUserProfileDocument,
    options,
  )
}
export function useAdminUserProfileSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminUserProfileQuery, Types.AdminUserProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<Types.AdminUserProfileQuery, Types.AdminUserProfileQueryVariables>(
    AdminUserProfileDocument,
    options,
  )
}
export type AdminUserProfileQueryHookResult = ReturnType<typeof useAdminUserProfileQuery>
export type AdminUserProfileLazyQueryHookResult = ReturnType<typeof useAdminUserProfileLazyQuery>
export type AdminUserProfileSuspenseQueryHookResult = ReturnType<typeof useAdminUserProfileSuspenseQuery>
export type AdminUserProfileQueryResult = Apollo.QueryResult<
  Types.AdminUserProfileQuery,
  Types.AdminUserProfileQueryVariables
>

import { clsx } from 'clsx'
import Link from 'next/link'

import { type SVGIcon } from 'utils/ts/shared-types'

export type MenuItemProps = {
  active?: boolean
  children: React.ReactNode
  href: string
  icon?: SVGIcon
}

const classes = {
  active: 'bg-gray-700 text-white',
  base: 'flex items-center px-2 py-2 text-sm font-semibold text-white rounded-md group',
  inactive: 'text-gray-300 hover:bg-gray-500 hover:text-white',
}

export function MenuItem({ active = false, children, href, icon }: MenuItemProps) {
  const Icon = icon

  return (
    <Link
      className={clsx(classes.base, {
        [classes.active]: active,
        [classes.inactive]: !active,
      })}
      href={href}
      role="menuitem"
    >
      {Icon && <Icon className="mr-3 h-6 w-6 sm:mr-4" />}
      {children}
    </Link>
  )
}

import { ApolloLink } from '@apollo/client'
import { isPlainObject, mapValues, pipe } from 'remeda'

import type { UnknownObject } from 'utils/ts/utility-types'

const ID_PREFIX = '__generated_'

const addId = (value: UnknownObject, lastId: string | undefined): ({ id: string } & typeof value) | typeof value => {
  return value.id == undefined && value._id == undefined && typeof lastId === 'string'
    ? { ...value, id: lastId.startsWith(ID_PREFIX) ? lastId.toLowerCase() : `${ID_PREFIX}${lastId.toLowerCase()}` }
    : value
}
export const deepAddCacheIdWhenMissing = (data: unknown, lastId: string | undefined): typeof data => {
  if (isPlainObject(data)) {
    return pipe(
      data,
      (input) => addId(input as UnknownObject, lastId),
      (input) =>
        mapValues(input, (value, key) =>
          deepAddCacheIdWhenMissing(value, typeof input.id === 'string' ? `${input.id}_${key}` : undefined),
        ),
    )
  }

  if (Array.isArray(data)) {
    return data.map((item, index) => deepAddCacheIdWhenMissing(item, lastId ? `${lastId}_${index}` : undefined))
  }

  return data
}

const isGeneratedId = (value: unknown) => typeof value === 'string' && value.startsWith(ID_PREFIX)

export const deepRemoveAddedCacheId = (data: unknown): typeof data => {
  if (isPlainObject(data)) {
    return pipe(
      data,
      (input) =>
        Object.fromEntries(
          Object.entries(input as UnknownObject).filter(([key, value]) => !(key === 'id' && isGeneratedId(value))),
        ),
      (input) => mapValues(input, (value) => deepRemoveAddedCacheId(value)),
    )
  }

  if (Array.isArray(data)) {
    return data.map((item) => deepRemoveAddedCacheId(item))
  }

  return data
}

/**
 * This adds an id field for every object that does not provide one by itself through the API (eg. TranslatedString)
 * The id is generated from the last known id provided by any parent and the key the non-normalized object is located under
 * Through this logic the generated ids resemble the path to the object-property from the last regularly normalizable object in the graph
 * eg. `${ID_PREFIX}_core_artist_data_<be_id_hash>_published_lastname`, this corresponds to the `lastname`-prop within the `published`-prop
 * from `CoreArtistData` which has an id in the backend AND which itself might be located at any nesting level within the tree.
 */
export const generateMissingCacheIds = () =>
  new ApolloLink((operation, forward) => {
    const variables = deepRemoveAddedCacheId(operation.variables)
    operation.variables = variables as UnknownObject
    return forward(operation).map(
      (data) => deepAddCacheIdWhenMissing(data as UnknownObject, undefined) as UnknownObject,
    )
  })

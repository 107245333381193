import { pipe } from 'remeda'

import type { Route } from 'utils/ts/shared-types'

import { ADMIN_USER_ROUTES } from 'features/admin-user-management/config'
import { ASSET_DATA_ROUTES } from 'features/asset-data/config'
import { CORE_DATA_ROUTES } from 'features/core-data/config'
import { CURATED_CONTENT_ROUTES } from 'features/curated-content/config'
import { IMPORTER_ROUTES } from 'features/importer/config'
import { LIVESTREAMS_ROUTES } from 'features/livestreams/config'
import { SEARCH_INDEX_ROUTES } from 'features/search-index/config'
import { TRANSCODING_ROUTES } from 'features/transcoding/config'
import { USER_ROUTES } from 'features/user-management/config'

export const TRANSLATION_KEY = 'navigation'

export const MENU_ITEMS: ReadonlyArray<Route> = Object.freeze([
  CORE_DATA_ROUTES.index,
  ASSET_DATA_ROUTES.index,
  CURATED_CONTENT_ROUTES.index,
  SEARCH_INDEX_ROUTES.index,
  IMPORTER_ROUTES.index,
  TRANSCODING_ROUTES.index,
  LIVESTREAMS_ROUTES.index,
  ADMIN_USER_ROUTES.index,
  USER_ROUTES.index,
])

export const getPageTitle = (pathname: string): string =>
  pipe(
    pathname,
    (pathname: string) =>
      MENU_ITEMS.find((item) => item.uri() === pathname) ?? MENU_ITEMS.find((item) => pathname.startsWith(item.uri())),
    (menuItem: Route | undefined) => (menuItem === undefined ? '' : menuItem.menuLabel ?? menuItem.label),
  )

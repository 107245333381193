const formatter = new Intl.RelativeTimeFormat('en')

type Range = readonly [interval: number, unit: Intl.RelativeTimeFormatUnit]
const ranges: ReadonlyArray<Range> = [
  [3600 * 24 * 365, 'years'],
  [3600 * 24 * 30, 'months'],
  [3600 * 24 * 7, 'weeks'],
  [3600 * 24, 'days'],
  [3600, 'hours'],
  [60, 'minutes'],
  [1, 'seconds'],
]

export function formatTimeAgo(input: Date | string): string {
  const date = input instanceof Date ? input : new Date(input)
  const secondsElapsed = (date.getTime() - Date.now()) / 1000
  const [interval, unit] = ranges.find((range) => range[0] < Math.abs(secondsElapsed)) as Range

  if (unit === 'seconds') {
    return 'just now'
  }

  const delta = secondsElapsed / interval
  return formatter.format(Math.round(delta), unit)
}

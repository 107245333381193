import { CloudArrowUpIcon } from '@heroicons/react/24/solid'
import { Role } from 'generated/types'

import type { RouteConfig } from 'utils/ts/shared-types'

export const IMPORTER_TRANSLATION_KEY = 'importer'

export const IMPORTER_ROUTES: RouteConfig = {
  index: {
    icon: CloudArrowUpIcon,
    label: 'Import New Albums',
    menuLabel: 'Importer',
    permissions: Role.Editor,
    uri: () => '/importer',
  },
  package: {
    label: 'Album Packager',
    permissions: Role.Editor,
    uri: () => '/importer/package',
  },
  packageStatus: {
    label: 'Album Packager Status',
    permissions: Role.Editor,
    uri: () => '/importer/package-status',
  },
  status: {
    label: 'Importer Status',
    permissions: Role.Editor,
    uri: () => '/importer/status',
  },
  upload: {
    label: 'Upload new data files',
    permissions: Role.Editor,
    uri: () => '/importer/upload',
  },
}

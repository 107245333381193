import { VideoCameraIcon } from '@heroicons/react/24/outline'
import { Role } from 'generated/types'

import type { RouteConfig } from 'utils/ts/shared-types'

export const TRANSCODING_TRANSLATION_KEY = 'importer'

export const TRANSCODING_ROUTES: RouteConfig = {
  index: {
    icon: VideoCameraIcon,
    label: 'Asset Transcoding',
    menuLabel: 'Transcoding',

    permissions: Role.Editor,
    uri: () => '/transcoding',
  },
}

import type { ComponentPropsWithoutRef } from 'react'

import Link from 'next/link'

import GrammophoneIcon from 'public/assets/grammophone.svg'
import { t } from 'utils/i18n/translate'

type LogoProps = ComponentPropsWithoutRef<'a'>

export function Logo({ ...props }: LogoProps) {
  return (
    <Link className="h-9 w-9" href="/" title={t('DG Stage Plus')} {...props}>
      <GrammophoneIcon className="h-9 w-9 text-yellow-dg" />
    </Link>
  )
}

import type { ComponentPropsWithoutRef } from 'react'

import { clsx } from 'clsx'

import { t } from 'utils/i18n/translate'

type CharacterCounterProps = {
  inputText?: string
  limit: number
  warnWhenCharsLeft?: number
} & ComponentPropsWithoutRef<'p'>

const classes = {
  base: 'sm:text-sm font-normal text-gray-500 whitespace-nowrap',
  overLimit: 'text-yellow-500',
}

export function CharacterCounter({
  className,
  inputText,
  limit,
  warnWhenCharsLeft = 20,
  ...props
}: CharacterCounterProps) {
  const difference = limit - (inputText?.length ?? 0)
  const hasInteracted = limit !== difference
  const direction = difference < 0 ? 'over' : 'left'

  return hasInteracted && difference <= warnWhenCharsLeft ? (
    <p className={clsx(classes.base, { [classes.overLimit]: difference < 0 }, className)} {...props}>
      {t('{{chars}} characters {{direction}}', { chars: Math.abs(difference), direction })}
    </p>
  ) : (
    <></>
  )
}

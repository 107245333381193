import type { ComponentPropsWithoutRef } from 'react'

import { clsx } from 'clsx'
import Link from 'next/link'

function Chevron({ className, ...props }: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      className={clsx('mx-2 h-2.5 w-2.5 flex-shrink-0 overflow-visible text-gray-400', className)}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 1L10.6869 7.16086C10.8637 7.35239 10.8637 7.64761 10.6869 7.83914L5 14"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  )
}

type BreadcrumbProps = ComponentPropsWithoutRef<'nav'>

export function Breadcrumb({ children, ...props }: BreadcrumbProps) {
  return (
    <nav aria-label="Breadcrumb" className="truncate" {...props}>
      <ol className="flex min-w-0 items-center whitespace-nowrap" role="list">
        {children}
      </ol>
    </nav>
  )
}

export type BreadcrumbItemProps = {
  href: string
  isLast?: boolean
  title: string
} & ComponentPropsWithoutRef<'li'>

export function BreadcrumbItem({ href, isLast = false, title, ...props }: BreadcrumbItemProps) {
  return (
    <li className="text-sm" {...props}>
      <Link className="flex items-center text-xs text-gray-500 hover:text-gray-700" href={href}>
        <div className="max-w-xs truncate text-ellipsis">{title}</div>
        {isLast ? null : <Chevron />}
      </Link>
    </li>
  )
}

/* eslint-disable @typescript-eslint/no-misused-promises */
import type { LoginAdminUserInput } from 'generated/types'

import React from 'react'
import { z } from 'zod'

import type { FormHelper } from 'components/form/form'

import { Button } from 'components/elements/button'
import { Fieldset } from 'components/elements/fieldset'
import { Form } from 'components/form/form'
import { FormActionsFooter } from 'components/form/form-actions-footer'
import { TextInput } from 'components/form/text-input'
import { requiredStringSchema } from 'utils/forms/validations'
import { getTranslation } from 'utils/i18n/translate'
import { getTranslationKeyFormatter } from 'utils/i18n/translation-key-formatter'

import { LOGIN_TRANSLATION_KEY } from './config'

type LoginFormProps = {
  onSubmit: (loginFormValues: LoginAdminUserInput, formActions: FormHelper<LoginAdminUserInput>) => Promise<unknown>
}

const loginFormSchema = z.object({
  email: requiredStringSchema().email({ message: 'E-Mail must be a valid email address.' }),
  // * we do not reuse the password-schema because we do not want to expose info about the password
  password: requiredStringSchema(),
})

const defaultInitialValues = {
  email: '',
  password: '',
}

const formId = 'login-form'
const t = getTranslation(LOGIN_TRANSLATION_KEY)
const tk = getTranslationKeyFormatter('login-form')

export function LoginForm({ onSubmit, ...props }: LoginFormProps) {
  return (
    <Form
      id={formId}
      initialValues={defaultInitialValues}
      onSubmit={onSubmit}
      shouldWarnOnDirty={false}
      validationSchema={loginFormSchema}
      {...props}
    >
      <Fieldset>
        <TextInput
          autoComplete="username"
          label={t(tk('email', 'label'), 'E-Mail')}
          name="email"
          placeholder={t(tk('email', 'placeholder'), 'max.mustermann@dgstage.com')}
        />
        <TextInput
          autoComplete="current-password"
          label={t(tk('password', 'label'), 'Password')}
          name="password"
          type="password"
        />
      </Fieldset>
      <FormActionsFooter>
        {({ handleSubmit }) => (
          <Button
            className="w-full justify-center sm:w-auto sm:justify-start"
            form={formId}
            onClick={handleSubmit(onSubmit)}
            type="submit"
          >
            {t(tk('sign_in', 'button'), 'Sign In')}
          </Button>
        )}
      </FormActionsFooter>
    </Form>
  )
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { setContext } from '@apollo/client/link/context'
import { isString } from 'remeda'

import { isBrowser } from 'utils/environment-guard'

const BEARER_TOKEN_KEY = 'dev_token'

const storage = isBrowser()
  ? window.localStorage
  : {
      getItem: (_key: string) => undefined,
      removeItem: (_key: string) => undefined,
      setItem: (_key: string, _value: string) => undefined,
    }

const isCrossOriginDevelopmentRequest = () =>
  process.env.NODE_ENV === 'development' &&
  isString(process.env.NEXT_PUBLIC_API_BASE_URI) &&
  !/localhost|local\.test/.test(process.env.NEXT_PUBLIC_API_BASE_URI)

export const setTokenForDevelopment = (token: string): void => {
  // ! should only be used in this particular case, read explanation below should you consider to
  // ! relax this. This is a temporary measure, should become obsolete with the introduction of UMG SSO.
  if (isCrossOriginDevelopmentRequest()) {
    storage.setItem(BEARER_TOKEN_KEY, token)
  }
}

const getToken = () => (isCrossOriginDevelopmentRequest() ? storage.getItem(BEARER_TOKEN_KEY) : undefined)

/**
 * Adds authentication header from token stored in state to outgoing requests when cors-requests in dev mode are detected
 * eg. when using local Admin UI with the Admin API in the staging environment. This makes it possible to run the
 * Admin UI with a different backend than a local one for debugging and development purposes (eg. when you do not want
 * to setup a local backend)
 *
 * ! Regular authentication is done with cookies, when the API is cross origin cookies will not be set because they
 * ! are sent with `SameSite=Strict` and thus are rejected when the local origin is different from the one the
 * ! Admin API runs under in another environment. **Only in this particular case** do we authenticate requests with an
 * ! authorization header. This should never be used on production
 */
export const developmentAuthLink = () =>
  setContext((_operation, { headers = {} }) => {
    const token = getToken()
    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      headers: token
        ? {
            ...headers,
            authorization: `Bearer ${token}`,
          }
        : headers,
    }
  })

import type { ReactNode } from 'react'

import { Role } from 'generated/types'

import type { RoutePermission } from 'utils/ts/shared-types'

import { NotificationElement } from 'components/elements/notification'
import { Spinner } from 'components/elements/spinner'
import { useAdminUser } from 'hooks/use-admin-user'
import { logger } from 'utils/error-reporting/logger'
import { t } from 'utils/i18n/translate'

type RoutePermissionGuardProps = {
  children: ReactNode
  minimumRequiredRole?: RoutePermission
}

const VIEW_PERMISSIONS_MAP: Record<Role, Role[]> = {
  [Role.CustomerCare]: [Role.CustomerCare, Role.Editor, Role.Superadmin],
  [Role.Editor]: [Role.Editor, Role.Superadmin],
  [Role.Superadmin]: [Role.Superadmin],
}

export const canUserEnterRoute = (minimumRequiredRole: RoutePermission, currentAdminUserRole?: Role): boolean => {
  if (minimumRequiredRole === 'all') {
    return true
  }

  if (currentAdminUserRole === undefined || minimumRequiredRole === 'nobody') {
    return false
  }

  return VIEW_PERMISSIONS_MAP?.[minimumRequiredRole]?.includes(currentAdminUserRole) ?? false
}

const needsUser = (minimumRequiredRole: RoutePermission | undefined): boolean => minimumRequiredRole !== 'all'

export function RoutePermissionGuard({ children, minimumRequiredRole }: RoutePermissionGuardProps) {
  const { adminUser, error, initial, loading } = useAdminUser()

  // * If no user is needed then we can immediately return the children without waiting for a user to be loaded
  if (!needsUser(minimumRequiredRole)) {
    return <>{children}</>
  }

  if (error && error.graphQLErrors.length > 0) {
    // * graphql-errors are handled in `graphql-error-link`
    // * this prevents rendering of further content, while the error is handled there
    return <Spinner isLoading={true} loadText={t('Redirecting to Login Page')} />
  }

  // ! this is non optimal as this only reports the network-error, if it happens on
  // ! the `profile`-request, this would be better handled in `graphQLErrorLink`
  // ! open question is how we would handle this, on any other request it would hurt if we lose
  // ! local state, eg. in forms
  if (error?.networkError) {
    logger.error(error.networkError, { level: 'info' })
    return (
      <NotificationElement layout="error">
        {t('Network Request failed. Please make sure you have an active internet-connection and try again.')}
      </NotificationElement>
    )
  }

  if (initial || loading) {
    return <Spinner isLoading={loading} />
  }

  if (minimumRequiredRole === undefined) {
    return (
      <NotificationElement layout="error">
        {t(
          'No permissions have been defined for this page. This is an unrecoverable error. Please report this to a developer. Please include the url you were trying to access in your report.',
        )}
      </NotificationElement>
    )
  }

  if (!canUserEnterRoute(minimumRequiredRole, adminUser?.role)) {
    return (
      <NotificationElement layout="error">
        {t(
          'You do not have sufficient permissions to view this page. If you think this is a mistake, please contact your Administrator.',
        )}
      </NotificationElement>
    )
  }

  return <>{children}</>
}

import type { ComponentPropsWithoutRef, ComponentPropsWithRef, ReactElement } from 'react'

import { clsx } from 'clsx'
import { cloneElement, forwardRef, isValidElement } from 'react'

import { preventDefaultBehavior } from 'utils/prevent-default-behavior'

import { buttonTokens } from './_tokens'

export type ButtonElementProps = {
  centered?: boolean
  disabled?: boolean
  icon?: ReactElement<ComponentPropsWithoutRef<'svg'>, 'svg'> // * Icon-Names can be found on https://heroicons.com/
  layout?: 'primary' | 'secondary'
} & ComponentPropsWithRef<'button'>

const classes = {
  base: [...buttonTokens.base, 'px-4', 'py-2', 'rounded-md', ...buttonTokens.focus],
  centered: 'justify-center',
  disabled: buttonTokens.disabled,
  icon: buttonTokens.icon,
  primary: buttonTokens.primary,
  secondary: buttonTokens.secondary,
}

export const Button = forwardRef<HTMLButtonElement, ButtonElementProps>(function Button(
  {
    centered = false,
    children,
    className,
    disabled = false,
    icon,
    layout = 'primary',
    onClick,
    type = 'button',
    ...props
  }: ButtonElementProps,
  reference,
) {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) {
      preventDefaultBehavior(event)
    } else {
      onClick?.(event)
    }
  }

  return (
    <button
      className={clsx(
        classes.base,
        classes[layout],
        { [classes.centered]: centered, [classes.disabled]: disabled, [classes.icon]: icon && !!children },
        className,
      )}
      disabled={disabled}
      onClick={handleClick}
      ref={reference}
      type={type}
      {...props}
    >
      {isValidElement(icon) &&
        cloneElement(icon, {
          'aria-hidden': true,
          className: clsx('h-4 w-4', { 'mr-2': !!children }),
        })}
      {children}
    </button>
  )
})

import { Transition } from '@headlessui/react'
import { clsx } from 'clsx'
import { Fragment } from 'react'

import { useModal } from 'state/use-modal'

export function ModalShim() {
  const { isVisible, modal } = useModal()
  return (
    <div className={clsx('fixed inset-0 z-30 overflow-y-auto', { 'pointer-events-none': !isVisible })}>
      <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
        <Transition
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          show={isVisible}
        >
          <div aria-hidden="true" className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
        </Transition>
        {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
        <span aria-hidden="true" className="hidden sm:inline-block sm:h-screen sm:align-middle">
          &#8203;
        </span>

        <Transition
          as="span"
          className="inline-block"
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          show={isVisible}
        >
          {modal}
        </Transition>
      </div>
    </div>
  )
}

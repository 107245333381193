import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { Role } from 'generated/types'

import type { RouteConfig } from 'utils/ts/shared-types'

export const SEARCH_INDEX_TRANSLATION_KEY = 'search_index'

export const SEARCH_INDEX_ROUTES: RouteConfig = {
  delete: {
    label: 'Delete Item',
    permissions: Role.Editor,
    uri: (index: string) => `/search-index/${index.toLowerCase()}/delete`,
  },
  index: {
    icon: MagnifyingGlassIcon,
    label: 'Search Index',
    menuLabel: 'Search Index',
    permissions: Role.Editor,
    uri: () => '/search-index',
  },
  reindex: {
    label: 'Reindex',
    permissions: Role.Editor,
    uri: (index: string) => `/search-index/${index.toLowerCase()}/reindex`,
  },
  taskSelection: {
    label: 'What do you want to do?',
    permissions: Role.Editor,
    uri: (index: string) => `/search-index/${index.toLowerCase()}`,
  },
}

import { Role } from 'generated/types'

import type { RouteConfig } from 'utils/ts/shared-types'

import CuratedContentIcon from 'public/assets/curated-content.svg'

export const CURATED_CONTENT_TRANSLATION_KEY = 'curated_content'

export const CURATED_CONTENT_LABEL = 'Curated Content'

export const CURATED_CONTENT_ROUTES: RouteConfig = {
  index: {
    icon: CuratedContentIcon,
    label: CURATED_CONTENT_LABEL,
    menuLabel: CURATED_CONTENT_LABEL,
    permissions: Role.Editor,
    uri: () => '/curated-content',
  },
}

import { addBreadcrumb } from '@sentry/browser'
import { LogoutAdminUserDocument } from 'generated/operations/login'

import { LOGIN_ROUTES } from 'features/login/config'
import { initializeApollo } from 'utils/apollo/client'
import { isBrowser } from 'utils/environment-guard'
import { logUnknownError } from 'utils/error-reporting/log-unknown-error'
import { goToUrlWithRefresh } from 'utils/navigation'

const getRedirectUrl = (message?: string) => {
  const url = new URL(LOGIN_ROUTES.index.uri(), window.location.origin)

  // * prevent the user being redirected to the login-page after login
  if (!window.location.pathname.startsWith(LOGIN_ROUTES.index.uri())) {
    url.searchParams.set('redirect', document.location.pathname)
  }

  if (message) {
    url.searchParams.set('message', message)
  }

  return url.toString()
}

const redirectToLoginPage = (message?: string) => {
  addBreadcrumb({ category: 'auth', message: 'Logout User' })

  // ! after many experimentation this turned out to be the most reliable way to clear all caches and state
  if (isBrowser()) {
    goToUrlWithRefresh(getRedirectUrl(message))
  }
}

export async function logout(message?: string) {
  const apolloClient = initializeApollo()
  try {
    await apolloClient.mutate({
      mutation: LogoutAdminUserDocument,
    })
  } catch (error: unknown) {
    logUnknownError(error)
  }

  redirectToLoginPage(message)
}

export function useLogout() {
  return { logout }
}

import type { ServerError } from '@apollo/client'
import type { Maybe } from 'generated/types'

import { onError } from '@apollo/client/link/error'
import { isError, isNumber } from 'remeda'
import { match, P } from 'ts-pattern'

import { LOGOUT_REASON_INVALID_TOKEN, LOGOUT_REASON_USER_DEACTIVATED } from 'features/login/login.page'
import { logout } from 'hooks/use-logout'
import { setException } from 'state/use-exception'

// * relevant errors passed from the BE in graphQLErrors as `message`
// ? if these become more we should probably centralize and systemize them and maybe use error codes
export const UNAUTHENTICATED_REQUEST = 'UNAUTHENTICATED'
const INVALID_AUTH_TOKEN = 'INVALID_AUTHORIZATION_TOKEN'
export const USER_DEACTIVATED = 'USER_DEACTIVATED'
const INSUFFICIENT_ROLE = 'ROLE_INSUFFICIENT'
// ! `not_found` error is not communicated in `code` but `message` this is a historic inconsistency
// ! in the backend and might be eventually fixed
export const NOT_FOUND_CODE = 'not_found'

export const isServerError = (error: unknown): error is ServerError => {
  return (
    isError(error) &&
    error?.name === 'ServerError' &&
    'statusCode' in error &&
    isNumber(error?.statusCode) &&
    error.statusCode >= 400
  )
}

const errorMatcher = (code: unknown, message: Maybe<string>) =>
  match([code, message])
    .with([P.union(UNAUTHENTICATED_REQUEST, INVALID_AUTH_TOKEN, INSUFFICIENT_ROLE), P._], () => {
      void logout(LOGOUT_REASON_INVALID_TOKEN)
      return 'redirect'
    })
    .with([USER_DEACTIVATED, P._], () => {
      void logout(LOGOUT_REASON_USER_DEACTIVATED)
      return 'redirect'
    })
    .with([P._, NOT_FOUND_CODE], () => {
      // * handled in components
      return 'noop'
    })
    .otherwise(() => 'error')

/**
 * ### Global error handler for graphql-errors
 *
 * GraphQL-Errors are mostly exceptional as user-errors are implemented as data but some other cases exist.
 * These might receive special handling here or be propagated to the corresponding components in order to be handled there.
 *
 * By default graphql-errors switch the whole application in an error state and the exception is logged
 * to sentry.io.
 *
 * ! This behavior could be more fine-grained, this deprioritized - feel free to change
 *
 * @see https://github.com/trsc/dg_stage_admin_frontend/blob/main/docs/decision-records/20210224-exception-handling.md
 */
export const graphQLErrorLink = onError(({ graphQLErrors, networkError, operation }) => {
  // * Server status codes >400 (this is graphql so this always indicates a serious problem in the backend)
  if (isServerError(networkError)) {
    setException(new Error('Network-Error during GraphQL-Request', { cause: networkError }), {
      extra: { operation: JSON.stringify(operation.operationName), variables: JSON.stringify(operation.variables) },
    })
  }

  if (graphQLErrors) {
    for (const error of graphQLErrors) {
      if (errorMatcher(error.extensions?.code, error.message) === 'error') {
        setException(new Error(`GraphQL-Errors during "${operation.operationName}"`), {
          extra: { graphQLErrors: JSON.stringify(graphQLErrors), variables: JSON.stringify(operation.variables) },
        })
      }
    }
  }
})

import type { ComponentPropsWithoutRef } from 'react'

import { CharacterCounter } from 'components/elements/character-counter'
import { HelpTextElement } from 'components/elements/help-text'
import { LabelElement } from 'components/elements/label'
import { TextInputElement } from 'components/elements/text-input'
import { useField } from 'hooks/use-field'

export type TextInputProps = {
  characterLimit?: number
  helpText?: string
  label?: string
  name: string
} & ComponentPropsWithoutRef<'input'>

export function TextInput({ characterLimit, className, helpText, label, name, readOnly, ...props }: TextInputProps) {
  const { description, descriptionId, field, labelId, validationState } = useField<string>({ helpText, name })

  return (
    <LabelElement className={className}>
      <div className="flex justify-between space-x-1">
        {label ? <span id={labelId}>{label}</span> : null}
        {characterLimit && (
          <CharacterCounter className="flex-grow-1 text-right" inputText={field.value} limit={characterLimit} />
        )}
      </div>
      <TextInputElement className="mt-1" readOnly={readOnly} validationState={validationState} {...field} {...props} />
      {description && (
        <HelpTextElement className="mt-2" id={descriptionId} readOnly={readOnly} validationState={validationState}>
          {description}
        </HelpTextElement>
      )}
    </LabelElement>
  )
}

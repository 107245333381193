import type { ComponentPropsWithRef } from 'react'

import { clsx } from 'clsx'
import { forwardRef } from 'react'

import { FieldValidationState } from 'hooks/use-field'

export type HelpTextElementProps = {
  readOnly?: boolean
  validationState: FieldValidationState
} & ComponentPropsWithRef<'p'>

const classes = {
  base: 'text-sm font-normal block leading-normal hyphens-auto whitespace-pre-wrap',
  error: 'text-red-600',
  untouched: 'text-gray-500',
  valid: 'text-green-600',
}

export const HelpTextElement = forwardRef<HTMLParagraphElement, HelpTextElementProps>(function HelpTextElement(
  { children, className, id, readOnly = false, validationState, ...props }: HelpTextElementProps,
  reference,
) {
  return (
    <div
      className={clsx(
        classes.base,
        {
          [classes.error]: validationState === FieldValidationState.error,
          [classes.untouched]: validationState === FieldValidationState.initial || readOnly === true,
          [classes.valid]: readOnly === false && validationState === FieldValidationState.valid,
        },
        className,
      )}
      id={id?.toLowerCase()}
      ref={reference}
      {...props}
    >
      {children}
    </div>
  )
})

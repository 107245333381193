import type { Role } from 'generated/types'

import { Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { clsx } from 'clsx'
import { useRouter } from 'next/router'

import { useAdminUser } from 'hooks/use-admin-user'
import { t } from 'utils/i18n/translate'

import { MENU_ITEMS } from './config'
import { Logo } from './logo'
import { MenuItem } from './menu-item'
import { canUserEnterRoute } from './route-permissions-guard'

const getMenuItems = (roleLoggedInAdminUser: Role | undefined) =>
  roleLoggedInAdminUser === undefined
    ? []
    : MENU_ITEMS.filter((item) => canUserEnterRoute(item.permissions, roleLoggedInAdminUser))

export function MenuSidebar() {
  const router = useRouter()
  const { adminUser } = useAdminUser()
  const menuItems = getMenuItems(adminUser?.role)

  return (
    <div className="hidden lg:flex lg:flex-shrink-0">
      <div className="flex w-64 flex-col">
        <div className="flex flex-grow flex-col overflow-y-auto bg-blue-dg pb-4 pt-5">
          <div className="flex flex-shrink-0 items-center justify-center px-4">
            <Logo className="-ml-1" />
          </div>
          <nav aria-label="Sidebar" className="mt-8 flex flex-1 flex-col overflow-y-auto">
            <div className="space-y-1 px-2">
              {menuItems.map((item) => (
                <MenuItem active={router.pathname.startsWith(item.uri())} href={item.uri()} key={item.uri()} {...item}>
                  {t(item.menuLabel ?? item.label)}
                </MenuItem>
              ))}
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}

type MenuMobileProps = {
  isMenuOpen: boolean
  setMenuOpen: (nextState: boolean) => void
}

export function MenuMobile({ isMenuOpen, setMenuOpen, ...props }: MenuMobileProps) {
  const router = useRouter()
  const { adminUser } = useAdminUser()
  const menuItems = getMenuItems(adminUser?.role)
  return (
    <div className={clsx('lg:hidden')} {...props}>
      <div className={clsx('fixed inset-0 z-40 flex', { 'pointer-events-none': !isMenuOpen })}>
        <Transition
          as={'div'}
          className="fixed inset-0"
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          show={isMenuOpen}
        >
          <div aria-hidden="true" className="absolute inset-0 bg-gray-600 opacity-75" />
        </Transition>

        <Transition
          as={'div'}
          className="relative flex h-full w-full max-w-xs flex-1 flex-col bg-blue-dg pb-4 pt-5"
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          show={isMenuOpen}
        >
          <div className="absolute right-0 top-0 -mr-12 pt-2">
            <button
              className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              onClick={() => setMenuOpen(false)}
            >
              <span className="sr-only">Close sidebar</span>
              <XMarkIcon className="h-6 w-6 text-white" />
            </button>
          </div>
          <div className="flex flex-shrink-0 items-center justify-center px-4">
            <Logo className="-ml-1" />
          </div>
          <nav aria-label="Sidebar" className="mt-8 h-full flex-shrink-0 overflow-y-auto">
            <div className="space-y-1 px-2">
              {menuItems.map((item) => (
                <MenuItem active={router.pathname.startsWith(item.uri())} href={item.uri()} key={item.uri()} {...item}>
                  {t(item.menuLabel ?? item.label)}
                </MenuItem>
              ))}
            </div>
          </nav>
          <div aria-hidden="true" className="w-14 flex-shrink-0">
            {/* Dummy element to force sidebar to shrink to fit close icon  */}
          </div>
        </Transition>
      </div>
    </div>
  )
}

import type { FetchResult, MutationFunctionOptions, MutationHookOptions, MutationResult } from '@apollo/client'
import type { ValidationError } from 'generated/types'
import type { DocumentNode, GraphQLType } from 'graphql'

import { useMutation } from '@apollo/client'
import { merge } from 'merge-anything'
import { filter, groupBy, pipe, reduce } from 'remeda'

import type { FormValidationErrors } from 'utils/forms/validations'
import type { TypedApiError } from 'utils/ts/shared-types'

import { isValidationError } from 'utils/graphql/errors'
import { isNotNullish, isObject } from 'utils/ts/type-guards'

export type AdminApiMutationPayload = {
  errors?: TypedApiError[] | undefined
  result?: unknown
} & GraphQLType

export type AdminApiMutationUserErrors = {
  apiError: TypedApiError | undefined
  validationErrors: FormValidationErrors<string> | undefined
}

export const isAdminApiMutationUserErrors = (
  maybeMutationError: unknown,
): maybeMutationError is AdminApiMutationUserErrors =>
  isObject(maybeMutationError) && ('apiError' in maybeMutationError || 'validationErrors' in maybeMutationError)

type AugmentedMutationResult<TData> = AdminApiMutationUserErrors & MutationResult<TData>
type AugmentedMutationTuple<TData, TVariables> = [
  (options?: MutationFunctionOptions<TData, TVariables>) => Promise<FetchResult<TData>>,
  AugmentedMutationResult<TData>,
]

/**
 * `pictures` is a single field that is split out for the forms into the pictures of interest to the Admin UI
 * This looks at the `path` of each error to determine the targeted picture type
 *
 * @param validationErrors
 * @returns
 */
const flattenPictureValidationErrors = (validationErrors: ValidationError[]): ValidationError[] =>
  pipe(
    validationErrors,
    groupBy((error) => (error.field === 'pictures' ? 'pictures' : 'other')),
    (errors) => {
      const pictureErrors =
        errors.pictures?.map((error) => ({ ...error, field: [...error.path].pop() ?? 'picture' })) ?? []
      const otherErrors = errors.other ?? []
      return [...otherErrors, ...pictureErrors]
    },
  )

const extractApiErrors = <TData>(data: TData | undefined): AdminApiMutationUserErrors | undefined => {
  if (data === undefined || data === null) {
    return undefined
  }

  const fields = Object.entries(data)
  const errors = fields.flatMap(([, value]: [string, AdminApiMutationPayload]) => value?.errors).filter(isNotNullish)

  const validationErrors = pipe(
    errors,
    (maybeErrors) => maybeErrors ?? [],
    filter(isValidationError),
    flattenPictureValidationErrors,
    reduce(
      (accumulator, error) =>
        merge(accumulator, {
          [error.field]: error.validation,
        }),
      {} as FormValidationErrors<string>,
    ),
  )

  // * all errors besides validation-error, are currently handled one at a time (by this code, but also by the backend)
  // * the user needs to submit again and again after fixing each individual error
  // * this is fine as long as the backend does not change its approach
  // ? if it does, it would require multiple notifications or some kind of aggregation
  const firstApiError = errors.find((error) => !isValidationError(error))

  return errors.length > 0
    ? {
        apiError: firstApiError,
        validationErrors: Object.keys(validationErrors).length > 0 ? validationErrors : undefined,
      }
    : undefined
}

export const useAdminApiMutation = <TData, TVariables>(
  gqlDocument: DocumentNode,
  baseOptions?: MutationHookOptions<TData, TVariables>,
): AugmentedMutationTuple<TData, TVariables> => {
  const [mutate, { data, ...rest }] = useMutation<TData, TVariables>(gqlDocument, baseOptions)

  const userErrors = extractApiErrors(data)

  const wrappedMutate = (options?: MutationFunctionOptions<TData, TVariables>) =>
    mutate(options).then((response) => {
      const userErrors = extractApiErrors(response.data)

      if (userErrors !== undefined) {
        throw userErrors
      }

      return response
    })
  return [
    wrappedMutate,
    {
      data: userErrors ? undefined : data,
      ...rest,
      ...(userErrors ?? { apiError: undefined, validationErrors: undefined }),
    },
  ]
}

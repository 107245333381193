import type {
  LoginAdminUserInput,
  LoginAdminUserMutation,
  LoginAdminUserMutationVariables,
  Maybe,
} from 'generated/types'

import { addBreadcrumb } from '@sentry/browser'
import { LoginAdminUserDocument } from 'generated/operations/login'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'

import type { FormHelper } from 'components/form/form'
import type { TypedApiError } from 'utils/ts/shared-types'

import { NotificationElement } from 'components/elements/notification'
import { PageIntro } from 'components/elements/page-intro'
import { Spinner } from 'components/elements/spinner'
import { NarrowFormContainer } from 'components/form/form'
import { isAdminApiMutationUserErrors, useAdminApiMutation } from 'hooks/use-admin-api-mutation'
import { useAdminUser } from 'hooks/use-admin-user'
import { setException } from 'state/use-exception'
import { setTokenForDevelopment } from 'utils/apollo/links/development-auth-link'
import { USER_DEACTIVATED } from 'utils/apollo/links/graphql-error-link'
import { logUnknownError } from 'utils/error-reporting/log-unknown-error'
import { isAuthenticationError } from 'utils/graphql/errors'
import { getTranslation } from 'utils/i18n/translate'
import { getTranslationKeyFormatter } from 'utils/i18n/translation-key-formatter'
import { enforceStringQueryParameter } from 'utils/next/routing'
import { isNullish } from 'utils/ts/type-guards'

import { LOGIN_ROUTES, LOGIN_TRANSLATION_KEY } from './config'
import { LoginForm } from './login-form'

export const LOGOUT_REASON_INVALID_TOKEN = 'invalid_token'
export const LOGOUT_REASON_USER_DEACTIVATED = 'user_deactivated'

const ERROR_CODE_INVALID_CREDENTIALS = 'INVALID_CREDENTIALS'

const t = getTranslation(LOGIN_TRANSLATION_KEY)
const tk = getTranslationKeyFormatter('login_page')

const isUserDeactivated = (apiError: Maybe<TypedApiError>, message: string | undefined) =>
  (isAuthenticationError(apiError) && apiError.code === USER_DEACTIVATED) || message === LOGOUT_REASON_USER_DEACTIVATED

const invalidUsernameOrPassword = (apiError: Maybe<TypedApiError>): boolean =>
  isAuthenticationError(apiError) && apiError.code === ERROR_CODE_INVALID_CREDENTIALS

export function LoginPage() {
  const { refetch } = useAdminUser()
  const router = useRouter()
  const redirectUri = enforceStringQueryParameter(router.query['redirect'])
  const message = enforceStringQueryParameter(router.query['message'])

  const [login, { apiError, loading }] = useAdminApiMutation<LoginAdminUserMutation, LoginAdminUserMutationVariables>(
    LoginAdminUserDocument,
  )

  const handleSubmit = (loginPayload: LoginAdminUserInput, formActions: FormHelper<LoginAdminUserInput>) =>
    login({ variables: { input: loginPayload } })
      .then((result) => {
        addBreadcrumb({ category: 'auth', message: 'Login User' })

        const token = result.data?.login?.result?.token
        if (token) {
          setTokenForDevelopment(token)
        }
        return refetch()
      })
      .then(() => {
        return router.push(redirectUri ?? '/')
      })
      .catch((error: unknown) => {
        if (isAdminApiMutationUserErrors(error) && isAuthenticationError(error?.apiError)) {
          throw error
        }

        logUnknownError(error)
        setException(undefined)
      })
      .catch(() => {
        formActions.resetForm({
          email: loginPayload.email,
          password: '',
        })
      })

  return (
    <>
      <Head>{LOGIN_ROUTES.index.label} | DG Stage Admin</Head>
      <NarrowFormContainer>
        <PageIntro headline={LOGIN_ROUTES.index.label} />
        <Spinner isLoading={loading} loadText={t(tk('message_loading'), 'Logging in')}>
          {isNullish(apiError) && message === LOGOUT_REASON_INVALID_TOKEN && (
            <NotificationElement aria-label="login-result" layout="warning">
              {t(tk('message_session_expired'), 'Your session expired. Please login again.')}
            </NotificationElement>
          )}
          {isUserDeactivated(apiError, message) ? (
            <NotificationElement aria-label="login-result" layout="error">
              {t(
                tk('message_user_deactivated'),
                'Your account has been deactivated. Please contact an Administrator for further assistance.',
              )}
            </NotificationElement>
          ) : null}
          {invalidUsernameOrPassword(apiError) && (
            <NotificationElement aria-label="login-result" layout="error">
              {t('Invalid username or password. Please try again.')}
            </NotificationElement>
          )}
          <LoginForm onSubmit={handleSubmit} />
        </Spinner>
      </NarrowFormContainer>
    </>
  )
}

export function getStaticProps() {
  return { props: { minimumRequiredRole: LOGIN_ROUTES.index.permissions } }
}

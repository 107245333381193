import type { Hit } from '@algolia/client-search'
import type { SearchIndex } from 'algoliasearch/lite'
import type { Scalars } from 'generated/types'

import algoliasearch from 'algoliasearch/lite'
import { isPlainObject, isString } from 'remeda'

import type { AppliedSearchFilter } from 'components/elements/search-filter-box/state'

import { logger } from 'utils/error-reporting/logger'
import { addBreadcrumb } from 'utils/error-reporting/sentry'
import { isNullish } from 'utils/ts/type-guards'

import type { SearchIndexBase, SearchIndexDocuments, SearchIndexDocumentTypes } from './document-types'

import { SearchIndexPublicationStatus, TagFilter } from './document-types'
import { getMoreRecentDataFromCache } from './optimistic-ui'

let _instance: SearchIndex | undefined

export function initializeSearchClient() {
  if (_instance !== undefined) {
    return _instance
  }

  const appId = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID
  const apiKey = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY
  const indexName = process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME

  if (isNullish(appId) || isNullish(apiKey) || isNullish(indexName)) {
    logger.error(new Error('Missing Env-Variables for Algolia configuration'), {
      extra: { apiKey: isNullish(apiKey) ? '<not set>' : 'set', appId, indexName },
    })

    return undefined
  }

  // ! Algolia caches requests and results based on query-parameters by default
  // * which is okay but good to know, check the documentation for more defaults
  // * @see https://www.algolia.com/doc/api-client/advanced/configure-the-client/javascript/?client=javascript
  const client = algoliasearch(appId, apiKey)
  const index = client.initIndex(indexName)
  _instance = index

  return _instance
}

export const search = _instance

const defaults = {
  attributesToRetrieve: ['objectId'],
}

export type ListViewSearchResult<HitType> = {
  hits: Array<Hit<HitType>>
  hitsPerPage: number
  isTotalHitsExhaustive: boolean
  page: number
  totalHits: number
  totalPages: number
}

export type SearchIndexSearchFunction<HitType> = (
  searchTerm: string,
  page: number,
  hitsPerPage: number,
  filters: AppliedSearchFilter[],
) => Promise<ListViewSearchResult<HitType>>

const tagFilterValues = new Set(Object.values(TagFilter))

const mapSearchFilter = (filter: AppliedSearchFilter[]) =>
  filter.reduce(
    (accumulator, [id, value]) => {
      return tagFilterValues.has(value as TagFilter)
        ? { ...accumulator, tagFilters: [...accumulator.tagFilters, value] }
        : {
            ...accumulator,
            facetFilters: [...accumulator.facetFilters, `${id}:${value}`],
          }
    },
    { facetFilters: [] as string[], tagFilters: [] as string[] },
  )

type ListViewSearchFunction = <HitType extends SearchIndexBase>(
  type: SearchIndexDocuments,
  attributesToRetrieve?: string[],
) => SearchIndexSearchFunction<HitType>

/**
 * Returns a search function that can be used in a list-view
 * allows for filtering and pagination
 */
export const getListViewSearch: ListViewSearchFunction =
  <HitType extends SearchIndexBase>(type: SearchIndexDocuments, attributesToRetrieve?: string[]) =>
  async (
    searchTerm: string,
    page: number,
    hitsPerPage: number,
    filters: AppliedSearchFilter[],
  ): Promise<ListViewSearchResult<HitType>> => {
    if (isNullish(_instance)) {
      throw new Error('Algolia has not been initialized')
    }

    addBreadcrumb({
      category: 'algolia',
      data: {
        filters: JSON.stringify(filters),
        hitsPerPage,
        page,
        searchTerm,
      },
      message: 'List search request sent',
      type: 'query',
    })

    try {
      const mappedFilters = mapSearchFilter(filters)
      const result = await _instance.search<HitType>(searchTerm, {
        attributesToRetrieve: attributesToRetrieve
          ? [...defaults.attributesToRetrieve, ...attributesToRetrieve]
          : ['*'],

        // ! this *seems* to trigger a more accurate calculation of exhaustive hit counts
        // ! we do not need the information itself. If this problem pops up again we might need to try
        // ! a more complex fix
        // ! for more information why Algolia hit counts are not guaranteed to be exact
        facetFilters: [`type:${type}`, ...mappedFilters.facetFilters],

        // ! {@link https://support.algolia.com/hc/en-us/articles/4406975248145-Why-are-my-facet-and-hit-counts-not-accurate-}
        facets: ['*'],
        hitsPerPage,
        page,
        tagFilters: mappedFilters.tagFilters,
      })

      result.hits = getMoreRecentDataFromCache(result.hits)

      addBreadcrumb({
        category: 'algolia',
        data: {
          filters: JSON.stringify(filters),
          hitsPerPage,
          numResults: result.hits.length,
          page,
          searchTerm,
        },
        message: 'List search result received',
        type: 'query',
      })

      return {
        hits: result.hits,
        hitsPerPage: result.hitsPerPage,
        isTotalHitsExhaustive: result.exhaustiveNbHits,
        page: result.page,
        // TODO: this needs to be corrected for possible dropped results in `getMoreRecentData` from cache
        totalHits: result.nbHits,
        totalPages: result.nbPages,
      }
    } catch (error) {
      throw handleError(error)
    }
  }

export type ReferencesSearchResult<HitType> = {
  hits: Array<Hit<HitType>>
}

/**
 * Searching for documents that can be used in references, eg. searching for an artist in a vod-concert
 */
export const getSearchForReferences =
  <HitType>(...types: SearchIndexDocuments[]) =>
  async (searchTerm: string): Promise<Array<Hit<HitType>>> => {
    if (isNullish(_instance)) {
      const error = new Error('Algolia has not been initialized')
      logger.error(error)
      throw error
    }

    addBreadcrumb({
      category: 'algolia',
      data: {
        searchTerm,
      },
      message: 'Reference search request sent',
      type: 'query',
    })

    try {
      const result = await _instance.search<HitType>(searchTerm, {
        facetFilters: [
          types.map((type) => `type:${type}`),
          [
            `publication_status:${SearchIndexPublicationStatus.published}`,
            `publication_status:${SearchIndexPublicationStatus.publishedWithDraft}`,
          ] as const,
        ],
        highlightPostTag: '</span>',
        highlightPreTag: '<span class="font-bold">',
        hitsPerPage: 50,
      })

      addBreadcrumb({
        category: 'algolia',
        data: {
          numResults: result.hits.length,
          searchTerm,
        },
        message: 'Reference search result received',
        type: 'query',
      })

      return result.hits
    } catch (error) {
      throw handleError(error)
    }
  }

/**
 * Returns the documents for an array of ids
 *
 * This is used eg. to resolve already existing references between content-types
 *
 * ! this has a theoretical limit of 1000 items, which is the max Algolia supports for one page
 * ! we could not imagine a situation where you would need to query more than 1000 ids, but should you
 * ! hit that limit you might need to introduce pagination
 */
export const getItemsById = async <HitType = SearchIndexDocumentTypes>(
  ids: Array<Scalars['ID']['output']>,
): Promise<Array<Hit<HitType>>> => {
  if (isNullish(_instance)) {
    const error = new Error('Algolia has not been initialized')
    logger.error(error)
    throw error
  }

  addBreadcrumb({
    category: 'algolia',
    data: {
      ids,
    },
    message: 'Requesting objects by id',
    type: 'query',
  })

  try {
    const result = await _instance.search<HitType>('', {
      facetFilters: [ids.map((id) => `objectID:${id}`)],
      hitsPerPage: 1000,
    })

    addBreadcrumb({
      category: 'algolia',
      data: {
        ids,
        numResults: result.hits.length,
      },
      message: 'Received objects by id',
      type: 'query',
    })

    return result.hits
  } catch (error) {
    throw handleError(error)
  }
}

const handleError = (error: unknown) => {
  if (error instanceof Error) {
    logger.error(error)
  } else {
    const message =
      isPlainObject(error) && isString(error.message)
        ? `[Algolia-Request failed]: ${error?.message}`
        : 'Request to Algolia failed.'
    logger.error(message, { extra: isPlainObject(error) ? error : undefined })
  }

  throw error
}

export type { Hit } from '@algolia/client-search'

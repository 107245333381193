/* eslint-disable import/no-named-as-default-member */
import type { ApolloError } from '@apollo/client'
import type { ApiError, Maybe, Node, Scalars } from 'generated/types'

import { AlbumPublicationStatus, PublicationStatus, UserStatus } from 'generated/types'
import { match, P } from 'ts-pattern'

import type { UnknownObject } from 'utils/ts/utility-types'

import { SearchIndexPublicationStatus } from 'utils/search/document-types'
import { isNullish } from 'utils/ts/type-guards'

export type ApiMutationResponse = {
  errors: Maybe<Array<ApiError>>
  result: Maybe<unknown>
}

export type ApiResultData<
  TDraft extends Maybe<UnknownObject> = UnknownObject,
  TPublished extends Maybe<UnknownObject> = UnknownObject,
> = {
  draft: Maybe<TDraft>
  id: Scalars['ID']['output']
  published: Maybe<TPublished>
  status: PublicationStatus
}

export function pickDraftOrPublished<TDraft extends Maybe<UnknownObject>, TPublished extends Maybe<UnknownObject>>(
  apiData: Maybe<ApiResultData<TDraft, TPublished>>,
): ((Node & TDraft) | (Node & TPublished)) | undefined {
  return match(apiData)
    .with(P.nullish, () => undefined)
    .with(
      {
        id: P.string,
        status: PublicationStatus.Published,
      },
      (result) => {
        if (isNullish(result.published)) {
          throw new Error('Document with publication status published, has no published version')
        }
        return {
          id: result.id,
          ...result.published,
        } as Node & TPublished
      },
    )
    .with(
      { id: P.string, status: PublicationStatus.Unpublished },
      { id: P.string, status: PublicationStatus.PublishedWithDraft },
      (result) => {
        if (isNullish(result.draft)) {
          throw new Error('Document with draft=publication status, has no draft version')
        }
        return {
          id: result.id,
          ...result.draft,
        }
      },
    )
    .otherwise(() => undefined)
}

export const publicationStatusToSearchIndexPublicationStatus = (
  status: AlbumPublicationStatus | PublicationStatus | UserStatus,
): SearchIndexPublicationStatus =>
  match(status)
    .with(PublicationStatus.PublishedWithDraft, () => SearchIndexPublicationStatus.publishedWithDraft)
    .with(
      PublicationStatus.Published,
      AlbumPublicationStatus.Published,
      UserStatus.Active,
      UserStatus.Blocked,
      () => SearchIndexPublicationStatus.published,
    )
    .with(PublicationStatus.Unpublished, AlbumPublicationStatus.Unpublished, () => SearchIndexPublicationStatus.draft)
    .with(
      PublicationStatus.Deleted,
      AlbumPublicationStatus.Deleted,
      UserStatus.Deleted,
      () => SearchIndexPublicationStatus.deleted,
    )
    .exhaustive()

const publicationStatusTexts = {
  draft: 'Item has only a draft version',
  published: 'Item has only a published version',
  publishedWithDraft: 'Item has both a published and draft version',
}

export const getPublicationStatusDescription = (status: PublicationStatus | SearchIndexPublicationStatus) =>
  match(status)
    .with(
      PublicationStatus.PublishedWithDraft,
      SearchIndexPublicationStatus.publishedWithDraft,
      () => publicationStatusTexts.publishedWithDraft,
    )
    .with(PublicationStatus.Published, SearchIndexPublicationStatus.published, () => publicationStatusTexts.published)
    .with(PublicationStatus.Unpublished, SearchIndexPublicationStatus.draft, () => publicationStatusTexts.draft)
    .with(PublicationStatus.Deleted, SearchIndexPublicationStatus.deleted, () => '')
    .exhaustive()

export function isResourceNotFoundError(error: ApolloError | undefined) {
  return error?.message === 'not_found'
}

import { isNumber } from 'remeda'
import { match, P } from 'ts-pattern'

import { isNullish } from 'utils/ts/type-guards'

import { translations } from './translations'

type TranslationFunctionOptions<TranslationNamespaces extends string = string> = {
  count?: number
  fallback?: string
  ns?: TranslationNamespaces
} & Record<string, number | string>

const interpolate = (input: string, variables: TranslationFunctionOptions | undefined) =>
  input.replaceAll(/{{\s*(.+?)\s*}}/g, (match: string, templateVariable: string) => {
    const [name, transform] = templateVariable.split(',')
    const value = variables?.[name]

    if (isNumber(value) && transform !== undefined && transform.trim() === 'timezoneCalculation') {
      return `you need to ${value < 0 ? 'subtract' : 'add'} ${Math.abs(value)}h from your local time`
    }

    return String(value ?? match)
  })

const translate = (
  bundle: Record<string, string> | undefined,
  keyPrefix: string,
  count?: number,
): string | undefined => {
  if (isNullish(bundle)) {
    return undefined
  }

  if (
    isNumber(count) &&
    bundle[`${keyPrefix}_zero`] !== undefined &&
    bundle[`${keyPrefix}_one`] !== undefined &&
    bundle[`${keyPrefix}_other`] !== undefined
  ) {
    if (count === 0) {
      return bundle[`${keyPrefix}_zero`]
    }

    if (count === 1) {
      return bundle[`${keyPrefix}_one`]
    }

    if (count > 1) {
      return bundle[`${keyPrefix}_other`]
    }
  }
  return bundle[keyPrefix]
}

const resolve = ({
  count,
  fallback,
  key,
  ns,
  translations,
  variables,
}: {
  count?: number
  fallback?: string
  key: string
  ns: string
  translations: Record<string, Record<string, string>>
  variables?: Record<string, number | string>
}) => {
  const bundle = translations[ns]
  const translation = translate(bundle, key, count)

  return interpolate(translation ?? fallback ?? key, isNumber(count) ? { ...variables, count } : variables)
}

export const getTranslationFactory =
  (translations: Record<string, Record<string, string>>) =>
  (ns: keyof typeof translations) =>
  (key: string, optionsOrDefault?: string | TranslationFunctionOptions<keyof typeof translations>) =>
    match([ns, key, optionsOrDefault] as const)
      .with([P.string, P.string, P.nullish], ([ns, key]) => {
        return translations[ns]?.[key] ?? key
      })
      .with([P.string, P.string, P.string], ([ns, key, fallback]) => translations[ns]?.[key] ?? fallback)
      .with(
        [P.string, P.string, { count: P.optional(P.number), fallback: P.optional(P.string), ns: P.optional(P.string) }],
        ([ns, key, { count, fallback, ns: nsOverride, ...rest }]) =>
          resolve({ count, fallback, key, ns: nsOverride ?? ns, translations, variables: rest }),
      )
      .exhaustive()

/**
 * Returns a translation function that is tied to the passed namespace.
 *
 * We use this for all UI-texts in order to have a structure in place that could support translations, should the need
 * arise in the future. It also offers reusability for ui-texts that are used in multiple places. Supported features
 * are interpolation, pluralization and custom transforms. See ADR for more information.
 *
 * @example
 *
 * const t = getTranslation(FORM_TRANSLATION_KEY)
 * t('validation_required', 'field is required') // define key and fallback as strings
 *
 * // define template variables in translations or fallbacks or keys, all these will become "Artist is required"
 * t("{{field}} is required", {field: "Artist"})
 * t("validation_required", {field: "Artist"}) // with validation_required defined as "{{field}} is required in `translations`"
 * t('validation_required', { fallback: '{{field}} is required', field: "Artist" })
 *
 * // use another namespace
 * t('next_button', {ns: 'common'})
 *
 * // use defined transform, will become "Your timezone is Europe/Berlin, you need to add 2h from your local time"
 * t('timezoneCalculation', { timezone: 'Europe/Berlin', timezoneOffset: 2 })
 *
 * // use pluralization, needs keys defined like this, `count` only valid template var
 * // { hits_zero: 'No Hits', hits_one: '1 Hit', hits_other: '{{count}} Hits}
 * t('hits', { count: 20 }) // 20 Hits
 */
export const getTranslation = (ns: string) => getTranslationFactory(translations)(ns)

/**
 * Global t function that is tied to the `common`-namespace, you can also pass a namespace explicitly.
 *
 * @example
 * t('next_button')
 * t('prev_button', 'Previous-Button')
 *
 * t('validation_required', {ns: FORM_TRANSLATION_KEY, field: "Artist"})
 */
export const t = getTranslation('common')

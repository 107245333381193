import { UserGroupIcon } from '@heroicons/react/24/solid'
import { Role } from 'generated/types'

import type { RouteConfig } from 'utils/ts/shared-types'

export const USER_TRANSLATION_KEY = 'customer_management'

export const USER_ROUTES: RouteConfig<'edit'> = {
  edit: {
    label: 'Customer Details',
    permissions: Role.CustomerCare,
    uri: (userId: string) => `/customer/${userId}`,
  },
  index: {
    icon: UserGroupIcon,
    label: 'Overview',
    menuLabel: 'Customer',
    permissions: Role.CustomerCare,
    uri: () => '/customer',
  },
}

import { PuzzlePieceIcon } from '@heroicons/react/24/outline'
import { Role } from 'generated/types'

import type { RouteConfig } from 'utils/ts/shared-types'

export const LIVESTREAMS_TRANSLATION_KEY = 'livestreams'

export const LIVESTREAMS_ROUTES: RouteConfig = {
  index: {
    icon: PuzzlePieceIcon,
    label: 'Livestreams',
    menuLabel: 'Livestreams',

    permissions: Role.Superadmin,
    uri: () => '/livestreams',
  },
}

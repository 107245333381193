import * as Types from '../types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export const AllLocalesFragmentDoc = gql`
  fragment AllLocales on TranslatedString {
    de
    en
    ja
  }
`
export const PictureInfoFragmentDoc = gql`
  fragment PictureInfo on Picture {
    id
    type
    url
  }
`
export const AlbumInfoFragmentDoc = gql`
  fragment AlbumInfo on Album {
    id
    status
    title {
      ...AllLocales
    }
    upc
    atmosUpc
    totalDuration
    copyright
    releaseDate
    originalReleaseDate
    artistAndGroupDisplayInfo {
      en
    }
    pictures {
      edges {
        node {
          ...PictureInfo
        }
      }
    }
    trackSets {
      id
      tracks {
        id
      }
    }
  }
  ${AllLocalesFragmentDoc}
  ${PictureInfoFragmentDoc}
`
export const ConnectedVodConcertInfoFragmentDoc = gql`
  fragment ConnectedVodConcertInfo on VodConcert {
    id
  }
`
export const ReferencedPicturesFragmentDoc = gql`
  fragment ReferencedPictures on PictureConnection {
    edges {
      status
      node {
        ...PictureInfo
      }
    }
  }
  ${PictureInfoFragmentDoc}
`
export const DraftEditorFragmentDoc = gql`
  fragment DraftEditor on AdminUser {
    id
    name
    email
  }
`
export const ReferencedGroupsFragmentDoc = gql`
  fragment ReferencedGroups on CoreGroupConnection {
    edges {
      status
      node {
        id
        name {
          en
        }
      }
    }
  }
`
export const ReferencedPartnersFragmentDoc = gql`
  fragment ReferencedPartners on CorePartnerConnection {
    edges {
      status
      node {
        id
        name {
          en
        }
        type
      }
    }
  }
`
export const ReferencedArtistsWithRoleFragmentDoc = gql`
  fragment ReferencedArtistsWithRole on CoreArtistWithRoleConnection {
    edges {
      status
      node {
        id
        name {
          en
        }
      }
      role {
        id
        type
        subtype
      }
    }
  }
`
export const ReferencedPerfomanceWorkFragmentDoc = gql`
  fragment ReferencedPerfomanceWork on PerformanceWork {
    id
    performanceIsrc
    hasUnpublishedChanges
    status
    stream {
      id
      url
    }
    cuePoints {
      id
      title {
        en
      }
    }
    artists {
      edges {
        node {
          id
          name {
            en
          }
        }
        role {
          id
          type
          subtype
        }
      }
    }
    work {
      id
      title {
        en
      }
      composers {
        edges {
          node {
            id
            name {
              en
            }
          }
        }
      }
    }
  }
`
export const ReferencedPerformanceWorksFragmentDoc = gql`
  fragment ReferencedPerformanceWorks on PerformanceWorkConnection {
    edges {
      status
      node {
        ...ReferencedPerfomanceWork
      }
    }
  }
  ${ReferencedPerfomanceWorkFragmentDoc}
`
export const RerunFragmentDoc = gql`
  fragment Rerun on LiveConcertRerun {
    title
    startTime
    streamStartTime
    endTime
  }
`
export const ContentContainerItemsFragmentDoc = gql`
  fragment ContentContainerItems on Node {
    id
  }
`
export const LiveConcertDraftInfoFragmentDoc = gql`
  fragment LiveConcertDraftInfo on LiveConcertDraft {
    vodConcert {
      ...ConnectedVodConcertInfo
    }
    copyright
    courtesyOf
    isrc
    trailerIsrc
    trailerStream {
      url
    }
    maxResolution
    isHdr
    isAtmos
    isLossless
    publicationLevel
    geoAccessMode
    geoAccessCountries
    startTime
    endTime
    streamStartTime
    type
    totalDuration
    licensor
    photographer
    plannedForVod
    pictures {
      ...ReferencedPictures
    }
    production
    seriesTitle {
      ...AllLocales
    }
    shortDescription {
      ...AllLocales
    }
    subtitle {
      ...AllLocales
    }
    title {
      ...AllLocales
    }
    editor {
      ...DraftEditor
    }
    updatedAt
    videoDirector
    longFormConcertIntroduction {
      ...AllLocales
    }
    groups {
      ...ReferencedGroups
    }
    partners {
      ...ReferencedPartners
    }
    artists {
      ...ReferencedArtistsWithRole
    }
    performanceWorks {
      ...ReferencedPerformanceWorks
    }
    reruns {
      ...Rerun
    }
    contentContainers {
      ...ContentContainerItems
    }
  }
  ${ConnectedVodConcertInfoFragmentDoc}
  ${ReferencedPicturesFragmentDoc}
  ${AllLocalesFragmentDoc}
  ${DraftEditorFragmentDoc}
  ${ReferencedGroupsFragmentDoc}
  ${ReferencedPartnersFragmentDoc}
  ${ReferencedArtistsWithRoleFragmentDoc}
  ${ReferencedPerformanceWorksFragmentDoc}
  ${RerunFragmentDoc}
  ${ContentContainerItemsFragmentDoc}
`
export const LiveConcertInfoFragmentDoc = gql`
  fragment LiveConcertInfo on LiveConcert {
    id
    vodConcert {
      ...ConnectedVodConcertInfo
    }
    copyright
    courtesyOf
    startTime
    endTime
    streamStartTime
    type
    totalDuration
    isrc
    trailerIsrc
    trailerStream {
      url
    }
    maxResolution
    isHdr
    isAtmos
    isLossless
    publicationLevel
    geoAccessMode
    geoAccessCountries
    licensor
    photographer
    plannedForVod
    pictures {
      ...ReferencedPictures
    }
    production
    seriesTitle {
      ...AllLocales
    }
    shortDescription {
      ...AllLocales
    }
    subtitle {
      ...AllLocales
    }
    title {
      ...AllLocales
    }
    videoDirector
    longFormConcertIntroduction {
      ...AllLocales
    }
    groups {
      ...ReferencedGroups
    }
    artists {
      ...ReferencedArtistsWithRole
    }
    partners {
      ...ReferencedPartners
    }
    performanceWorks {
      ...ReferencedPerformanceWorks
    }
    reruns {
      ...Rerun
    }
    contentContainers {
      ...ContentContainerItems
    }
    editor {
      ...DraftEditor
    }
    updatedAt
  }
  ${ConnectedVodConcertInfoFragmentDoc}
  ${ReferencedPicturesFragmentDoc}
  ${AllLocalesFragmentDoc}
  ${ReferencedGroupsFragmentDoc}
  ${ReferencedArtistsWithRoleFragmentDoc}
  ${ReferencedPartnersFragmentDoc}
  ${ReferencedPerformanceWorksFragmentDoc}
  ${RerunFragmentDoc}
  ${ContentContainerItemsFragmentDoc}
  ${DraftEditorFragmentDoc}
`
export const LiveConcertDataFragmentDoc = gql`
  fragment LiveConcertData on LiveConcertData {
    id
    status
    draft {
      ...LiveConcertDraftInfo
    }
    published {
      ...LiveConcertInfo
    }
  }
  ${LiveConcertDraftInfoFragmentDoc}
  ${LiveConcertInfoFragmentDoc}
`
export const StreamInfoFragmentDoc = gql`
  fragment StreamInfo on Stream {
    id
    url
    mvVideoUrl
  }
`
export const CuePointInfoFragmentDoc = gql`
  fragment CuePointInfo on CuePoint {
    id
    mark
    title {
      ...AllLocales
    }
  }
  ${AllLocalesFragmentDoc}
`
export const ParentConcertInfoFragmentDoc = gql`
  fragment ParentConcertInfo on ParentConcert {
    ... on LiveConcert {
      id
      title {
        en
      }
    }
    ... on VodConcert {
      id
      title {
        en
      }
    }
  }
`
export const CoreWorkInfoFragmentDoc = gql`
  fragment CoreWorkInfo on CoreWork {
    id
    title {
      en
    }
    movements {
      id
      title {
        ...AllLocales
      }
    }
  }
  ${AllLocalesFragmentDoc}
`
export const PerformanceWorkDraftInfoFragmentDoc = gql`
  fragment PerformanceWorkDraftInfo on PerformanceWorkDraft {
    duration
    performanceIsrc
    mvIsrc
    stream {
      ...StreamInfo
    }
    cuePoints {
      ...CuePointInfo
    }
    parentConcert {
      ...ParentConcertInfo
    }
    artists {
      ...ReferencedArtistsWithRole
    }
    groups {
      ...ReferencedGroups
    }
    work {
      ...CoreWorkInfo
    }
    editor {
      ...DraftEditor
    }
    updatedAt
  }
  ${StreamInfoFragmentDoc}
  ${CuePointInfoFragmentDoc}
  ${ParentConcertInfoFragmentDoc}
  ${ReferencedArtistsWithRoleFragmentDoc}
  ${ReferencedGroupsFragmentDoc}
  ${CoreWorkInfoFragmentDoc}
  ${DraftEditorFragmentDoc}
`
export const PerformanceWorkInfoFragmentDoc = gql`
  fragment PerformanceWorkInfo on PerformanceWork {
    id
    duration
    performanceIsrc
    mvIsrc
    stream {
      ...StreamInfo
    }
    cuePoints {
      ...CuePointInfo
    }
    parentConcert {
      ...ParentConcertInfo
    }
    artists {
      ...ReferencedArtistsWithRole
    }
    groups {
      ...ReferencedGroups
    }
    work {
      ...CoreWorkInfo
    }
    status
    hasUnpublishedChanges
    editor {
      ...DraftEditor
    }
    updatedAt
  }
  ${StreamInfoFragmentDoc}
  ${CuePointInfoFragmentDoc}
  ${ParentConcertInfoFragmentDoc}
  ${ReferencedArtistsWithRoleFragmentDoc}
  ${ReferencedGroupsFragmentDoc}
  ${CoreWorkInfoFragmentDoc}
  ${DraftEditorFragmentDoc}
`
export const PerformanceWorkDataFragmentDoc = gql`
  fragment PerformanceWorkData on PerformanceWorkData {
    id
    status
    draft {
      ...PerformanceWorkDraftInfo
    }
    published {
      ...PerformanceWorkInfo
    }
  }
  ${PerformanceWorkDraftInfoFragmentDoc}
  ${PerformanceWorkInfoFragmentDoc}
`
export const VideoConcertInfoFragmentDoc = gql`
  fragment VideoConcertInfo on LiveOrVodConcert {
    ... on LiveConcert {
      id
      title {
        en
      }
    }
    ... on VodConcert {
      id
      title {
        en
      }
    }
  }
`
export const ReferencedAlbumsFragmentDoc = gql`
  fragment ReferencedAlbums on AlbumConnection {
    edges {
      status
      node {
        id
        title {
          ...AllLocales
        }
      }
    }
  }
  ${AllLocalesFragmentDoc}
`
export const VideoDraftInfoFragmentDoc = gql`
  fragment VideoDraftInfo on VideoDraft {
    copyright
    courtesyOf
    type
    isrc
    trailerIsrc
    publicationLevel
    geoAccessMode
    geoAccessCountries
    maxResolution
    isHdr
    isAtmos
    isLossless
    stream {
      ...StreamInfo
    }
    trailerStream {
      ...StreamInfo
    }
    pictures {
      ...ReferencedPictures
    }
    description {
      ...AllLocales
    }
    title {
      ...AllLocales
    }
    subtitle {
      ...AllLocales
    }
    concerts {
      edges {
        status
        node {
          ...VideoConcertInfo
        }
      }
    }
    albums {
      ...ReferencedAlbums
    }
    partners {
      ...ReferencedPartners
    }
    archiveReleaseDate
    takedownDate
    productionDate
    duration
    copyright
    courtesyOf
    licensor
    photographer
    production
    videoDirector
    contentContainers {
      ...ContentContainerItems
    }
    editor {
      ...DraftEditor
    }
    updatedAt
    groups {
      ...ReferencedGroups
    }
    artists {
      ...ReferencedArtistsWithRole
    }
    work {
      id
      title {
        en
      }
    }
  }
  ${StreamInfoFragmentDoc}
  ${ReferencedPicturesFragmentDoc}
  ${AllLocalesFragmentDoc}
  ${VideoConcertInfoFragmentDoc}
  ${ReferencedAlbumsFragmentDoc}
  ${ReferencedPartnersFragmentDoc}
  ${ContentContainerItemsFragmentDoc}
  ${DraftEditorFragmentDoc}
  ${ReferencedGroupsFragmentDoc}
  ${ReferencedArtistsWithRoleFragmentDoc}
`
export const VideoInfoFragmentDoc = gql`
  fragment VideoInfo on Video {
    id
    copyright
    courtesyOf
    type
    isrc
    trailerIsrc
    publicationLevel
    geoAccessMode
    geoAccessCountries
    maxResolution
    isHdr
    isAtmos
    isLossless
    stream {
      ...StreamInfo
    }
    trailerStream {
      ...StreamInfo
    }
    pictures {
      ...ReferencedPictures
    }
    description {
      ...AllLocales
    }
    title {
      ...AllLocales
    }
    subtitle {
      ...AllLocales
    }
    concerts {
      edges {
        status
        node {
          ...VideoConcertInfo
        }
      }
    }
    albums {
      ...ReferencedAlbums
    }
    partners {
      ...ReferencedPartners
    }
    archiveReleaseDate
    takedownDate
    productionDate
    duration
    copyright
    courtesyOf
    licensor
    photographer
    production
    videoDirector
    groups {
      ...ReferencedGroups
    }
    artists {
      ...ReferencedArtistsWithRole
    }
    work {
      id
      title {
        en
      }
    }
    contentContainers {
      ...ContentContainerItems
    }
    editor {
      ...DraftEditor
    }
    updatedAt
  }
  ${StreamInfoFragmentDoc}
  ${ReferencedPicturesFragmentDoc}
  ${AllLocalesFragmentDoc}
  ${VideoConcertInfoFragmentDoc}
  ${ReferencedAlbumsFragmentDoc}
  ${ReferencedPartnersFragmentDoc}
  ${ReferencedGroupsFragmentDoc}
  ${ReferencedArtistsWithRoleFragmentDoc}
  ${ContentContainerItemsFragmentDoc}
  ${DraftEditorFragmentDoc}
`
export const VideoDataFragmentDoc = gql`
  fragment VideoData on VideoData {
    id
    status
    draft {
      ...VideoDraftInfo
    }
    published {
      ...VideoInfo
    }
  }
  ${VideoDraftInfoFragmentDoc}
  ${VideoInfoFragmentDoc}
`
export const ConnectedLiveConcertInfoFragmentDoc = gql`
  fragment ConnectedLiveConcertInfo on LiveConcert {
    id
  }
`
export const VodConcertDraftInfoFragmentDoc = gql`
  fragment VodConcertDraftInfo on VodConcertDraft {
    liveConcert {
      ...ConnectedLiveConcertInfo
    }
    copyright
    courtesyOf
    type
    totalDuration
    airDate
    productionDate
    archiveReleaseDate
    takedownDate
    isrc
    trailerIsrc
    trailerStream {
      url
    }
    licensor
    photographer
    pictures {
      ...ReferencedPictures
    }
    production
    seriesTitle {
      ...AllLocales
    }
    shortDescription {
      ...AllLocales
    }
    subtitle {
      ...AllLocales
    }
    title {
      ...AllLocales
    }
    editor {
      ...DraftEditor
    }
    updatedAt
    videoDirector
    longFormConcertIntroduction {
      ...AllLocales
    }
    groups {
      ...ReferencedGroups
    }
    partners {
      ...ReferencedPartners
    }
    artists {
      ...ReferencedArtistsWithRole
    }
    performanceWorks {
      ...ReferencedPerformanceWorks
    }
    geoAccessMode
    geoAccessCountries
    maxResolution
    isHdr
    isAtmos
    isLossless
    isMvVideo
    isImmersive
    publicationLevel
    contentContainers {
      ...ContentContainerItems
    }
  }
  ${ConnectedLiveConcertInfoFragmentDoc}
  ${ReferencedPicturesFragmentDoc}
  ${AllLocalesFragmentDoc}
  ${DraftEditorFragmentDoc}
  ${ReferencedGroupsFragmentDoc}
  ${ReferencedPartnersFragmentDoc}
  ${ReferencedArtistsWithRoleFragmentDoc}
  ${ReferencedPerformanceWorksFragmentDoc}
  ${ContentContainerItemsFragmentDoc}
`
export const VodConcertInfoFragmentDoc = gql`
  fragment VodConcertInfo on VodConcert {
    id
    liveConcert {
      ...ConnectedLiveConcertInfo
    }
    copyright
    courtesyOf
    airDate
    type
    isMvVideo
    isImmersive
    totalDuration
    productionDate
    archiveReleaseDate
    takedownDate
    isrc
    trailerIsrc
    trailerStream {
      url
    }
    licensor
    photographer
    pictures {
      ...ReferencedPictures
    }
    production
    seriesTitle {
      ...AllLocales
    }
    shortDescription {
      ...AllLocales
    }
    subtitle {
      ...AllLocales
    }
    title {
      ...AllLocales
    }
    videoDirector
    longFormConcertIntroduction {
      ...AllLocales
    }
    groups {
      ...ReferencedGroups
    }
    partners {
      ...ReferencedPartners
    }
    artists {
      ...ReferencedArtistsWithRole
    }
    performanceWorks {
      ...ReferencedPerformanceWorks
    }
    geoAccessMode
    geoAccessCountries
    maxResolution
    isHdr
    isAtmos
    isLossless
    publicationLevel
    contentContainers {
      ...ContentContainerItems
    }
    updatedAt
    editor {
      ...DraftEditor
    }
  }
  ${ConnectedLiveConcertInfoFragmentDoc}
  ${ReferencedPicturesFragmentDoc}
  ${AllLocalesFragmentDoc}
  ${ReferencedGroupsFragmentDoc}
  ${ReferencedPartnersFragmentDoc}
  ${ReferencedArtistsWithRoleFragmentDoc}
  ${ReferencedPerformanceWorksFragmentDoc}
  ${ContentContainerItemsFragmentDoc}
  ${DraftEditorFragmentDoc}
`
export const VodConcertDataFragmentDoc = gql`
  fragment VodConcertData on VodConcertData {
    id
    status
    draft {
      ...VodConcertDraftInfo
    }
    published {
      ...VodConcertInfo
    }
  }
  ${VodConcertDraftInfoFragmentDoc}
  ${VodConcertInfoFragmentDoc}
`
export const PublicationErrorFieldsFragmentDoc = gql`
  fragment PublicationErrorFields on PublicationError {
    reason
  }
`
export const UniqueIsrcPublicationErrorFieldsFragmentDoc = gql`
  fragment UniqueIsrcPublicationErrorFields on UniqueIsrcPublicationError {
    reason
    isrc
    conflictingNodes {
      id
      type
    }
  }
`
export const ValidationErrorFieldsFragmentDoc = gql`
  fragment ValidationErrorFields on ValidationError {
    field
    validation
  }
`
export const AdminApiErrorFragmentDoc = gql`
  fragment AdminApiError on ApiError {
    __typename
    message
    path
    ... on PublicationError {
      ...PublicationErrorFields
    }
    ... on UniqueIsrcPublicationError {
      ...UniqueIsrcPublicationErrorFields
    }
    ... on ValidationError {
      ...ValidationErrorFields
    }
    ... on AuthenticationError {
      code
    }
  }
  ${PublicationErrorFieldsFragmentDoc}
  ${UniqueIsrcPublicationErrorFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`
export const ReferencedArtistsFragmentDoc = gql`
  fragment ReferencedArtists on CoreArtistConnection {
    edges {
      status
      node {
        id
        name {
          en
        }
      }
    }
  }
`
export const RoleInfoFragmentDoc = gql`
  fragment RoleInfo on CoreRole {
    id
    type
    subtype
    displayName
  }
`
export const ReferencedRolesFragmentDoc = gql`
  fragment ReferencedRoles on CoreRoleConnection {
    edges {
      status
      node {
        ...RoleInfo
      }
    }
  }
  ${RoleInfoFragmentDoc}
`
export const ReferencedListRolesFragmentDoc = gql`
  fragment ReferencedListRoles on CoreRoleListConnection {
    edges {
      node {
        ...RoleInfo
      }
    }
  }
  ${RoleInfoFragmentDoc}
`
export const PageInfoFragmentDoc = gql`
  fragment PageInfo on PageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`
export const AdminUserInfoFragmentDoc = gql`
  fragment AdminUserInfo on AdminUser {
    id
    name
    role
    email
    isActive
  }
`
export const PublishAlbumDocument = gql`
  mutation publishAlbum($input: PublishAlbumInput!) {
    publishAlbum(input: $input) {
      errors {
        ...AdminApiError
      }
      result {
        ...AlbumInfo
      }
    }
  }
  ${AdminApiErrorFragmentDoc}
  ${AlbumInfoFragmentDoc}
`
export type PublishAlbumMutationFn = Apollo.MutationFunction<
  Types.PublishAlbumMutation,
  Types.PublishAlbumMutationVariables
>

/**
 * __usePublishAlbumMutation__
 *
 * To run a mutation, you first call `usePublishAlbumMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishAlbumMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishAlbumMutation, { data, loading, error }] = usePublishAlbumMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishAlbumMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.PublishAlbumMutation, Types.PublishAlbumMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.PublishAlbumMutation, Types.PublishAlbumMutationVariables>(
    PublishAlbumDocument,
    options,
  )
}
export type PublishAlbumMutationHookResult = ReturnType<typeof usePublishAlbumMutation>
export type PublishAlbumMutationResult = Apollo.MutationResult<Types.PublishAlbumMutation>
export type PublishAlbumMutationOptions = Apollo.BaseMutationOptions<
  Types.PublishAlbumMutation,
  Types.PublishAlbumMutationVariables
>
export const UnpublishAlbumDocument = gql`
  mutation unpublishAlbum($input: UnpublishAlbumInput!) {
    unpublishAlbum(input: $input) {
      errors {
        ...AdminApiError
      }
      result {
        ...AlbumInfo
      }
    }
  }
  ${AdminApiErrorFragmentDoc}
  ${AlbumInfoFragmentDoc}
`
export type UnpublishAlbumMutationFn = Apollo.MutationFunction<
  Types.UnpublishAlbumMutation,
  Types.UnpublishAlbumMutationVariables
>

/**
 * __useUnpublishAlbumMutation__
 *
 * To run a mutation, you first call `useUnpublishAlbumMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishAlbumMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishAlbumMutation, { data, loading, error }] = useUnpublishAlbumMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpublishAlbumMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UnpublishAlbumMutation, Types.UnpublishAlbumMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.UnpublishAlbumMutation, Types.UnpublishAlbumMutationVariables>(
    UnpublishAlbumDocument,
    options,
  )
}
export type UnpublishAlbumMutationHookResult = ReturnType<typeof useUnpublishAlbumMutation>
export type UnpublishAlbumMutationResult = Apollo.MutationResult<Types.UnpublishAlbumMutation>
export type UnpublishAlbumMutationOptions = Apollo.BaseMutationOptions<
  Types.UnpublishAlbumMutation,
  Types.UnpublishAlbumMutationVariables
>
export const DeleteAlbumDocument = gql`
  mutation deleteAlbum($input: DeleteAlbumInput) {
    deleteAlbum(input: $input) {
      errors {
        ...AdminApiError
      }
      result {
        ...AlbumInfo
      }
    }
  }
  ${AdminApiErrorFragmentDoc}
  ${AlbumInfoFragmentDoc}
`
export type DeleteAlbumMutationFn = Apollo.MutationFunction<
  Types.DeleteAlbumMutation,
  Types.DeleteAlbumMutationVariables
>

/**
 * __useDeleteAlbumMutation__
 *
 * To run a mutation, you first call `useDeleteAlbumMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAlbumMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAlbumMutation, { data, loading, error }] = useDeleteAlbumMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAlbumMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.DeleteAlbumMutation, Types.DeleteAlbumMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.DeleteAlbumMutation, Types.DeleteAlbumMutationVariables>(DeleteAlbumDocument, options)
}
export type DeleteAlbumMutationHookResult = ReturnType<typeof useDeleteAlbumMutation>
export type DeleteAlbumMutationResult = Apollo.MutationResult<Types.DeleteAlbumMutation>
export type DeleteAlbumMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteAlbumMutation,
  Types.DeleteAlbumMutationVariables
>
export const GetAlbumByIdDocument = gql`
  query getAlbumById($id: ID!) {
    album(id: $id) {
      ...AlbumInfo
    }
  }
  ${AlbumInfoFragmentDoc}
`

/**
 * __useGetAlbumByIdQuery__
 *
 * To run a query within a React component, call `useGetAlbumByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlbumByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlbumByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAlbumByIdQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetAlbumByIdQuery, Types.GetAlbumByIdQueryVariables> &
    ({ variables: Types.GetAlbumByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetAlbumByIdQuery, Types.GetAlbumByIdQueryVariables>(GetAlbumByIdDocument, options)
}
export function useGetAlbumByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAlbumByIdQuery, Types.GetAlbumByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetAlbumByIdQuery, Types.GetAlbumByIdQueryVariables>(GetAlbumByIdDocument, options)
}
export function useGetAlbumByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetAlbumByIdQuery, Types.GetAlbumByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<Types.GetAlbumByIdQuery, Types.GetAlbumByIdQueryVariables>(
    GetAlbumByIdDocument,
    options,
  )
}
export type GetAlbumByIdQueryHookResult = ReturnType<typeof useGetAlbumByIdQuery>
export type GetAlbumByIdLazyQueryHookResult = ReturnType<typeof useGetAlbumByIdLazyQuery>
export type GetAlbumByIdSuspenseQueryHookResult = ReturnType<typeof useGetAlbumByIdSuspenseQuery>
export type GetAlbumByIdQueryResult = Apollo.QueryResult<Types.GetAlbumByIdQuery, Types.GetAlbumByIdQueryVariables>
export const GetLiveConcertByIdDocument = gql`
  query getLiveConcertById($id: ID!) {
    liveConcert(id: $id) {
      ...LiveConcertData
    }
  }
  ${LiveConcertDataFragmentDoc}
`

/**
 * __useGetLiveConcertByIdQuery__
 *
 * To run a query within a React component, call `useGetLiveConcertByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiveConcertByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiveConcertByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLiveConcertByIdQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetLiveConcertByIdQuery, Types.GetLiveConcertByIdQueryVariables> &
    ({ variables: Types.GetLiveConcertByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetLiveConcertByIdQuery, Types.GetLiveConcertByIdQueryVariables>(
    GetLiveConcertByIdDocument,
    options,
  )
}
export function useGetLiveConcertByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLiveConcertByIdQuery, Types.GetLiveConcertByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetLiveConcertByIdQuery, Types.GetLiveConcertByIdQueryVariables>(
    GetLiveConcertByIdDocument,
    options,
  )
}
export function useGetLiveConcertByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetLiveConcertByIdQuery, Types.GetLiveConcertByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<Types.GetLiveConcertByIdQuery, Types.GetLiveConcertByIdQueryVariables>(
    GetLiveConcertByIdDocument,
    options,
  )
}
export type GetLiveConcertByIdQueryHookResult = ReturnType<typeof useGetLiveConcertByIdQuery>
export type GetLiveConcertByIdLazyQueryHookResult = ReturnType<typeof useGetLiveConcertByIdLazyQuery>
export type GetLiveConcertByIdSuspenseQueryHookResult = ReturnType<typeof useGetLiveConcertByIdSuspenseQuery>
export type GetLiveConcertByIdQueryResult = Apollo.QueryResult<
  Types.GetLiveConcertByIdQuery,
  Types.GetLiveConcertByIdQueryVariables
>
export const SaveLiveConcertDraftDocument = gql`
  mutation saveLiveConcertDraft($input: CreateOrUpdateLiveConcertDraftInput!) {
    createOrUpdateLiveConcertDraft(input: $input) {
      errors {
        ...AdminApiError
      }
      result {
        ...LiveConcertData
      }
    }
  }
  ${AdminApiErrorFragmentDoc}
  ${LiveConcertDataFragmentDoc}
`
export type SaveLiveConcertDraftMutationFn = Apollo.MutationFunction<
  Types.SaveLiveConcertDraftMutation,
  Types.SaveLiveConcertDraftMutationVariables
>

/**
 * __useSaveLiveConcertDraftMutation__
 *
 * To run a mutation, you first call `useSaveLiveConcertDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveLiveConcertDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveLiveConcertDraftMutation, { data, loading, error }] = useSaveLiveConcertDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveLiveConcertDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SaveLiveConcertDraftMutation,
    Types.SaveLiveConcertDraftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.SaveLiveConcertDraftMutation, Types.SaveLiveConcertDraftMutationVariables>(
    SaveLiveConcertDraftDocument,
    options,
  )
}
export type SaveLiveConcertDraftMutationHookResult = ReturnType<typeof useSaveLiveConcertDraftMutation>
export type SaveLiveConcertDraftMutationResult = Apollo.MutationResult<Types.SaveLiveConcertDraftMutation>
export type SaveLiveConcertDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.SaveLiveConcertDraftMutation,
  Types.SaveLiveConcertDraftMutationVariables
>
export const PublishLiveConcertDocument = gql`
  mutation publishLiveConcert($input: PublishLiveConcertInput!) {
    publishLiveConcert(input: $input) {
      errors {
        ...AdminApiError
      }
      result {
        ...LiveConcertData
      }
    }
  }
  ${AdminApiErrorFragmentDoc}
  ${LiveConcertDataFragmentDoc}
`
export type PublishLiveConcertMutationFn = Apollo.MutationFunction<
  Types.PublishLiveConcertMutation,
  Types.PublishLiveConcertMutationVariables
>

/**
 * __usePublishLiveConcertMutation__
 *
 * To run a mutation, you first call `usePublishLiveConcertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishLiveConcertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishLiveConcertMutation, { data, loading, error }] = usePublishLiveConcertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishLiveConcertMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.PublishLiveConcertMutation, Types.PublishLiveConcertMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.PublishLiveConcertMutation, Types.PublishLiveConcertMutationVariables>(
    PublishLiveConcertDocument,
    options,
  )
}
export type PublishLiveConcertMutationHookResult = ReturnType<typeof usePublishLiveConcertMutation>
export type PublishLiveConcertMutationResult = Apollo.MutationResult<Types.PublishLiveConcertMutation>
export type PublishLiveConcertMutationOptions = Apollo.BaseMutationOptions<
  Types.PublishLiveConcertMutation,
  Types.PublishLiveConcertMutationVariables
>
export const UnpublishLiveConcertDocument = gql`
  mutation unpublishLiveConcert($input: UnpublishLiveConcertInput!) {
    unpublishLiveConcert(input: $input) {
      errors {
        ...AdminApiError
      }
      result {
        ...LiveConcertData
      }
    }
  }
  ${AdminApiErrorFragmentDoc}
  ${LiveConcertDataFragmentDoc}
`
export type UnpublishLiveConcertMutationFn = Apollo.MutationFunction<
  Types.UnpublishLiveConcertMutation,
  Types.UnpublishLiveConcertMutationVariables
>

/**
 * __useUnpublishLiveConcertMutation__
 *
 * To run a mutation, you first call `useUnpublishLiveConcertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishLiveConcertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishLiveConcertMutation, { data, loading, error }] = useUnpublishLiveConcertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpublishLiveConcertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UnpublishLiveConcertMutation,
    Types.UnpublishLiveConcertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.UnpublishLiveConcertMutation, Types.UnpublishLiveConcertMutationVariables>(
    UnpublishLiveConcertDocument,
    options,
  )
}
export type UnpublishLiveConcertMutationHookResult = ReturnType<typeof useUnpublishLiveConcertMutation>
export type UnpublishLiveConcertMutationResult = Apollo.MutationResult<Types.UnpublishLiveConcertMutation>
export type UnpublishLiveConcertMutationOptions = Apollo.BaseMutationOptions<
  Types.UnpublishLiveConcertMutation,
  Types.UnpublishLiveConcertMutationVariables
>
export const DeleteLiveConcertDraftDocument = gql`
  mutation deleteLiveConcertDraft($input: DeleteLiveConcertDraftInput!) {
    deleteLiveConcertDraft(input: $input) {
      errors {
        ...AdminApiError
      }
      result {
        ...LiveConcertData
      }
    }
  }
  ${AdminApiErrorFragmentDoc}
  ${LiveConcertDataFragmentDoc}
`
export type DeleteLiveConcertDraftMutationFn = Apollo.MutationFunction<
  Types.DeleteLiveConcertDraftMutation,
  Types.DeleteLiveConcertDraftMutationVariables
>

/**
 * __useDeleteLiveConcertDraftMutation__
 *
 * To run a mutation, you first call `useDeleteLiveConcertDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLiveConcertDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLiveConcertDraftMutation, { data, loading, error }] = useDeleteLiveConcertDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteLiveConcertDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteLiveConcertDraftMutation,
    Types.DeleteLiveConcertDraftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.DeleteLiveConcertDraftMutation, Types.DeleteLiveConcertDraftMutationVariables>(
    DeleteLiveConcertDraftDocument,
    options,
  )
}
export type DeleteLiveConcertDraftMutationHookResult = ReturnType<typeof useDeleteLiveConcertDraftMutation>
export type DeleteLiveConcertDraftMutationResult = Apollo.MutationResult<Types.DeleteLiveConcertDraftMutation>
export type DeleteLiveConcertDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteLiveConcertDraftMutation,
  Types.DeleteLiveConcertDraftMutationVariables
>
export const CopyLiveToVodConcertDocument = gql`
  mutation copyLiveToVodConcert($input: CopyLiveToVodConcertInput!) {
    copyLiveToVodConcert(input: $input) {
      errors {
        ...AdminApiError
      }
      result {
        ...LiveConcertData
      }
    }
  }
  ${AdminApiErrorFragmentDoc}
  ${LiveConcertDataFragmentDoc}
`
export type CopyLiveToVodConcertMutationFn = Apollo.MutationFunction<
  Types.CopyLiveToVodConcertMutation,
  Types.CopyLiveToVodConcertMutationVariables
>

/**
 * __useCopyLiveToVodConcertMutation__
 *
 * To run a mutation, you first call `useCopyLiveToVodConcertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyLiveToVodConcertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyLiveToVodConcertMutation, { data, loading, error }] = useCopyLiveToVodConcertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyLiveToVodConcertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CopyLiveToVodConcertMutation,
    Types.CopyLiveToVodConcertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.CopyLiveToVodConcertMutation, Types.CopyLiveToVodConcertMutationVariables>(
    CopyLiveToVodConcertDocument,
    options,
  )
}
export type CopyLiveToVodConcertMutationHookResult = ReturnType<typeof useCopyLiveToVodConcertMutation>
export type CopyLiveToVodConcertMutationResult = Apollo.MutationResult<Types.CopyLiveToVodConcertMutation>
export type CopyLiveToVodConcertMutationOptions = Apollo.BaseMutationOptions<
  Types.CopyLiveToVodConcertMutation,
  Types.CopyLiveToVodConcertMutationVariables
>
export const GetPerformanceWorkByIdDocument = gql`
  query GetPerformanceWorkById($id: ID!) {
    performanceWork(id: $id) {
      ...PerformanceWorkData
    }
  }
  ${PerformanceWorkDataFragmentDoc}
`

/**
 * __useGetPerformanceWorkByIdQuery__
 *
 * To run a query within a React component, call `useGetPerformanceWorkByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPerformanceWorkByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPerformanceWorkByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPerformanceWorkByIdQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetPerformanceWorkByIdQuery, Types.GetPerformanceWorkByIdQueryVariables> &
    ({ variables: Types.GetPerformanceWorkByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetPerformanceWorkByIdQuery, Types.GetPerformanceWorkByIdQueryVariables>(
    GetPerformanceWorkByIdDocument,
    options,
  )
}
export function useGetPerformanceWorkByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetPerformanceWorkByIdQuery,
    Types.GetPerformanceWorkByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetPerformanceWorkByIdQuery, Types.GetPerformanceWorkByIdQueryVariables>(
    GetPerformanceWorkByIdDocument,
    options,
  )
}
export function useGetPerformanceWorkByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetPerformanceWorkByIdQuery,
    Types.GetPerformanceWorkByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<Types.GetPerformanceWorkByIdQuery, Types.GetPerformanceWorkByIdQueryVariables>(
    GetPerformanceWorkByIdDocument,
    options,
  )
}
export type GetPerformanceWorkByIdQueryHookResult = ReturnType<typeof useGetPerformanceWorkByIdQuery>
export type GetPerformanceWorkByIdLazyQueryHookResult = ReturnType<typeof useGetPerformanceWorkByIdLazyQuery>
export type GetPerformanceWorkByIdSuspenseQueryHookResult = ReturnType<typeof useGetPerformanceWorkByIdSuspenseQuery>
export type GetPerformanceWorkByIdQueryResult = Apollo.QueryResult<
  Types.GetPerformanceWorkByIdQuery,
  Types.GetPerformanceWorkByIdQueryVariables
>
export const GetMovementsDocument = gql`
  query getMovements($id: ID!) {
    coreWork(id: $id) {
      status
      published {
        movements {
          id
          title {
            ...AllLocales
          }
        }
      }
    }
  }
  ${AllLocalesFragmentDoc}
`

/**
 * __useGetMovementsQuery__
 *
 * To run a query within a React component, call `useGetMovementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMovementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMovementsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMovementsQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetMovementsQuery, Types.GetMovementsQueryVariables> &
    ({ variables: Types.GetMovementsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetMovementsQuery, Types.GetMovementsQueryVariables>(GetMovementsDocument, options)
}
export function useGetMovementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMovementsQuery, Types.GetMovementsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetMovementsQuery, Types.GetMovementsQueryVariables>(GetMovementsDocument, options)
}
export function useGetMovementsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetMovementsQuery, Types.GetMovementsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<Types.GetMovementsQuery, Types.GetMovementsQueryVariables>(
    GetMovementsDocument,
    options,
  )
}
export type GetMovementsQueryHookResult = ReturnType<typeof useGetMovementsQuery>
export type GetMovementsLazyQueryHookResult = ReturnType<typeof useGetMovementsLazyQuery>
export type GetMovementsSuspenseQueryHookResult = ReturnType<typeof useGetMovementsSuspenseQuery>
export type GetMovementsQueryResult = Apollo.QueryResult<Types.GetMovementsQuery, Types.GetMovementsQueryVariables>
export const SavePerformanceWorkDraftDocument = gql`
  mutation savePerformanceWorkDraft($input: CreateOrUpdatePerformanceWorkDraftInput!) {
    createOrUpdatePerformanceWorkDraft(input: $input) {
      result {
        ...PerformanceWorkData
      }
    }
  }
  ${PerformanceWorkDataFragmentDoc}
`
export type SavePerformanceWorkDraftMutationFn = Apollo.MutationFunction<
  Types.SavePerformanceWorkDraftMutation,
  Types.SavePerformanceWorkDraftMutationVariables
>

/**
 * __useSavePerformanceWorkDraftMutation__
 *
 * To run a mutation, you first call `useSavePerformanceWorkDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePerformanceWorkDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePerformanceWorkDraftMutation, { data, loading, error }] = useSavePerformanceWorkDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSavePerformanceWorkDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SavePerformanceWorkDraftMutation,
    Types.SavePerformanceWorkDraftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.SavePerformanceWorkDraftMutation, Types.SavePerformanceWorkDraftMutationVariables>(
    SavePerformanceWorkDraftDocument,
    options,
  )
}
export type SavePerformanceWorkDraftMutationHookResult = ReturnType<typeof useSavePerformanceWorkDraftMutation>
export type SavePerformanceWorkDraftMutationResult = Apollo.MutationResult<Types.SavePerformanceWorkDraftMutation>
export type SavePerformanceWorkDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.SavePerformanceWorkDraftMutation,
  Types.SavePerformanceWorkDraftMutationVariables
>
export const PublishPerformanceWorkDocument = gql`
  mutation publishPerformanceWork($input: PublishPerformanceWorkInput!) {
    publishPerformanceWork(input: $input) {
      errors {
        ...AdminApiError
      }
      result {
        ...PerformanceWorkData
      }
    }
  }
  ${AdminApiErrorFragmentDoc}
  ${PerformanceWorkDataFragmentDoc}
`
export type PublishPerformanceWorkMutationFn = Apollo.MutationFunction<
  Types.PublishPerformanceWorkMutation,
  Types.PublishPerformanceWorkMutationVariables
>

/**
 * __usePublishPerformanceWorkMutation__
 *
 * To run a mutation, you first call `usePublishPerformanceWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishPerformanceWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishPerformanceWorkMutation, { data, loading, error }] = usePublishPerformanceWorkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishPerformanceWorkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.PublishPerformanceWorkMutation,
    Types.PublishPerformanceWorkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.PublishPerformanceWorkMutation, Types.PublishPerformanceWorkMutationVariables>(
    PublishPerformanceWorkDocument,
    options,
  )
}
export type PublishPerformanceWorkMutationHookResult = ReturnType<typeof usePublishPerformanceWorkMutation>
export type PublishPerformanceWorkMutationResult = Apollo.MutationResult<Types.PublishPerformanceWorkMutation>
export type PublishPerformanceWorkMutationOptions = Apollo.BaseMutationOptions<
  Types.PublishPerformanceWorkMutation,
  Types.PublishPerformanceWorkMutationVariables
>
export const UnpublishPerformanceWorkDocument = gql`
  mutation unpublishPerformanceWork($input: UnpublishPerformanceWorkInput!) {
    unpublishPerformanceWork(input: $input) {
      errors {
        ...AdminApiError
      }
      result {
        ...PerformanceWorkData
      }
    }
  }
  ${AdminApiErrorFragmentDoc}
  ${PerformanceWorkDataFragmentDoc}
`
export type UnpublishPerformanceWorkMutationFn = Apollo.MutationFunction<
  Types.UnpublishPerformanceWorkMutation,
  Types.UnpublishPerformanceWorkMutationVariables
>

/**
 * __useUnpublishPerformanceWorkMutation__
 *
 * To run a mutation, you first call `useUnpublishPerformanceWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishPerformanceWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishPerformanceWorkMutation, { data, loading, error }] = useUnpublishPerformanceWorkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpublishPerformanceWorkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UnpublishPerformanceWorkMutation,
    Types.UnpublishPerformanceWorkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.UnpublishPerformanceWorkMutation, Types.UnpublishPerformanceWorkMutationVariables>(
    UnpublishPerformanceWorkDocument,
    options,
  )
}
export type UnpublishPerformanceWorkMutationHookResult = ReturnType<typeof useUnpublishPerformanceWorkMutation>
export type UnpublishPerformanceWorkMutationResult = Apollo.MutationResult<Types.UnpublishPerformanceWorkMutation>
export type UnpublishPerformanceWorkMutationOptions = Apollo.BaseMutationOptions<
  Types.UnpublishPerformanceWorkMutation,
  Types.UnpublishPerformanceWorkMutationVariables
>
export const DeletePerformanceWorkDraftDocument = gql`
  mutation deletePerformanceWorkDraft($input: DeletePerformanceWorkDraftInput!) {
    deletePerformanceWorkDraft(input: $input) {
      errors {
        ...AdminApiError
      }
      result {
        ...PerformanceWorkData
      }
    }
  }
  ${AdminApiErrorFragmentDoc}
  ${PerformanceWorkDataFragmentDoc}
`
export type DeletePerformanceWorkDraftMutationFn = Apollo.MutationFunction<
  Types.DeletePerformanceWorkDraftMutation,
  Types.DeletePerformanceWorkDraftMutationVariables
>

/**
 * __useDeletePerformanceWorkDraftMutation__
 *
 * To run a mutation, you first call `useDeletePerformanceWorkDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePerformanceWorkDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePerformanceWorkDraftMutation, { data, loading, error }] = useDeletePerformanceWorkDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePerformanceWorkDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeletePerformanceWorkDraftMutation,
    Types.DeletePerformanceWorkDraftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeletePerformanceWorkDraftMutation,
    Types.DeletePerformanceWorkDraftMutationVariables
  >(DeletePerformanceWorkDraftDocument, options)
}
export type DeletePerformanceWorkDraftMutationHookResult = ReturnType<typeof useDeletePerformanceWorkDraftMutation>
export type DeletePerformanceWorkDraftMutationResult = Apollo.MutationResult<Types.DeletePerformanceWorkDraftMutation>
export type DeletePerformanceWorkDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.DeletePerformanceWorkDraftMutation,
  Types.DeletePerformanceWorkDraftMutationVariables
>
export const GetVideoByIdDocument = gql`
  query getVideoById($id: ID!) {
    video(id: $id) {
      ...VideoData
    }
  }
  ${VideoDataFragmentDoc}
`

/**
 * __useGetVideoByIdQuery__
 *
 * To run a query within a React component, call `useGetVideoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVideoByIdQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetVideoByIdQuery, Types.GetVideoByIdQueryVariables> &
    ({ variables: Types.GetVideoByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetVideoByIdQuery, Types.GetVideoByIdQueryVariables>(GetVideoByIdDocument, options)
}
export function useGetVideoByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetVideoByIdQuery, Types.GetVideoByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetVideoByIdQuery, Types.GetVideoByIdQueryVariables>(GetVideoByIdDocument, options)
}
export function useGetVideoByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetVideoByIdQuery, Types.GetVideoByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<Types.GetVideoByIdQuery, Types.GetVideoByIdQueryVariables>(
    GetVideoByIdDocument,
    options,
  )
}
export type GetVideoByIdQueryHookResult = ReturnType<typeof useGetVideoByIdQuery>
export type GetVideoByIdLazyQueryHookResult = ReturnType<typeof useGetVideoByIdLazyQuery>
export type GetVideoByIdSuspenseQueryHookResult = ReturnType<typeof useGetVideoByIdSuspenseQuery>
export type GetVideoByIdQueryResult = Apollo.QueryResult<Types.GetVideoByIdQuery, Types.GetVideoByIdQueryVariables>
export const SaveVideoDraftDocument = gql`
  mutation saveVideoDraft($input: CreateOrUpdateVideoDraftInput!) {
    createOrUpdateVideoDraft(input: $input) {
      result {
        ...VideoData
      }
    }
  }
  ${VideoDataFragmentDoc}
`
export type SaveVideoDraftMutationFn = Apollo.MutationFunction<
  Types.SaveVideoDraftMutation,
  Types.SaveVideoDraftMutationVariables
>

/**
 * __useSaveVideoDraftMutation__
 *
 * To run a mutation, you first call `useSaveVideoDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveVideoDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveVideoDraftMutation, { data, loading, error }] = useSaveVideoDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveVideoDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.SaveVideoDraftMutation, Types.SaveVideoDraftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.SaveVideoDraftMutation, Types.SaveVideoDraftMutationVariables>(
    SaveVideoDraftDocument,
    options,
  )
}
export type SaveVideoDraftMutationHookResult = ReturnType<typeof useSaveVideoDraftMutation>
export type SaveVideoDraftMutationResult = Apollo.MutationResult<Types.SaveVideoDraftMutation>
export type SaveVideoDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.SaveVideoDraftMutation,
  Types.SaveVideoDraftMutationVariables
>
export const PublishVideoDocument = gql`
  mutation publishVideo($input: PublishVideoInput!) {
    publishVideo(input: $input) {
      errors {
        ...AdminApiError
      }
      result {
        ...VideoData
      }
    }
  }
  ${AdminApiErrorFragmentDoc}
  ${VideoDataFragmentDoc}
`
export type PublishVideoMutationFn = Apollo.MutationFunction<
  Types.PublishVideoMutation,
  Types.PublishVideoMutationVariables
>

/**
 * __usePublishVideoMutation__
 *
 * To run a mutation, you first call `usePublishVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishVideoMutation, { data, loading, error }] = usePublishVideoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishVideoMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.PublishVideoMutation, Types.PublishVideoMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.PublishVideoMutation, Types.PublishVideoMutationVariables>(
    PublishVideoDocument,
    options,
  )
}
export type PublishVideoMutationHookResult = ReturnType<typeof usePublishVideoMutation>
export type PublishVideoMutationResult = Apollo.MutationResult<Types.PublishVideoMutation>
export type PublishVideoMutationOptions = Apollo.BaseMutationOptions<
  Types.PublishVideoMutation,
  Types.PublishVideoMutationVariables
>
export const UnpublishVideoDocument = gql`
  mutation unpublishVideo($input: UnpublishVideoInput!) {
    unpublishVideo(input: $input) {
      errors {
        ...AdminApiError
      }
      result {
        ...VideoData
      }
    }
  }
  ${AdminApiErrorFragmentDoc}
  ${VideoDataFragmentDoc}
`
export type UnpublishVideoMutationFn = Apollo.MutationFunction<
  Types.UnpublishVideoMutation,
  Types.UnpublishVideoMutationVariables
>

/**
 * __useUnpublishVideoMutation__
 *
 * To run a mutation, you first call `useUnpublishVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishVideoMutation, { data, loading, error }] = useUnpublishVideoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpublishVideoMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UnpublishVideoMutation, Types.UnpublishVideoMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.UnpublishVideoMutation, Types.UnpublishVideoMutationVariables>(
    UnpublishVideoDocument,
    options,
  )
}
export type UnpublishVideoMutationHookResult = ReturnType<typeof useUnpublishVideoMutation>
export type UnpublishVideoMutationResult = Apollo.MutationResult<Types.UnpublishVideoMutation>
export type UnpublishVideoMutationOptions = Apollo.BaseMutationOptions<
  Types.UnpublishVideoMutation,
  Types.UnpublishVideoMutationVariables
>
export const DeleteVideoDraftDocument = gql`
  mutation deleteVideoDraft($input: DeleteVideoDraftInput!) {
    deleteVideoDraft(input: $input) {
      errors {
        ...AdminApiError
      }
      result {
        ...VideoData
      }
    }
  }
  ${AdminApiErrorFragmentDoc}
  ${VideoDataFragmentDoc}
`
export type DeleteVideoDraftMutationFn = Apollo.MutationFunction<
  Types.DeleteVideoDraftMutation,
  Types.DeleteVideoDraftMutationVariables
>

/**
 * __useDeleteVideoDraftMutation__
 *
 * To run a mutation, you first call `useDeleteVideoDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVideoDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVideoDraftMutation, { data, loading, error }] = useDeleteVideoDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVideoDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.DeleteVideoDraftMutation, Types.DeleteVideoDraftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.DeleteVideoDraftMutation, Types.DeleteVideoDraftMutationVariables>(
    DeleteVideoDraftDocument,
    options,
  )
}
export type DeleteVideoDraftMutationHookResult = ReturnType<typeof useDeleteVideoDraftMutation>
export type DeleteVideoDraftMutationResult = Apollo.MutationResult<Types.DeleteVideoDraftMutation>
export type DeleteVideoDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteVideoDraftMutation,
  Types.DeleteVideoDraftMutationVariables
>
export const GetVodConcertByIdDocument = gql`
  query getVodConcertById($id: ID!) {
    vodConcert(id: $id) {
      ...VodConcertData
    }
  }
  ${VodConcertDataFragmentDoc}
`

/**
 * __useGetVodConcertByIdQuery__
 *
 * To run a query within a React component, call `useGetVodConcertByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVodConcertByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVodConcertByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVodConcertByIdQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetVodConcertByIdQuery, Types.GetVodConcertByIdQueryVariables> &
    ({ variables: Types.GetVodConcertByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetVodConcertByIdQuery, Types.GetVodConcertByIdQueryVariables>(
    GetVodConcertByIdDocument,
    options,
  )
}
export function useGetVodConcertByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetVodConcertByIdQuery, Types.GetVodConcertByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetVodConcertByIdQuery, Types.GetVodConcertByIdQueryVariables>(
    GetVodConcertByIdDocument,
    options,
  )
}
export function useGetVodConcertByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetVodConcertByIdQuery, Types.GetVodConcertByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<Types.GetVodConcertByIdQuery, Types.GetVodConcertByIdQueryVariables>(
    GetVodConcertByIdDocument,
    options,
  )
}
export type GetVodConcertByIdQueryHookResult = ReturnType<typeof useGetVodConcertByIdQuery>
export type GetVodConcertByIdLazyQueryHookResult = ReturnType<typeof useGetVodConcertByIdLazyQuery>
export type GetVodConcertByIdSuspenseQueryHookResult = ReturnType<typeof useGetVodConcertByIdSuspenseQuery>
export type GetVodConcertByIdQueryResult = Apollo.QueryResult<
  Types.GetVodConcertByIdQuery,
  Types.GetVodConcertByIdQueryVariables
>
export const SaveVodConcertDraftDocument = gql`
  mutation saveVodConcertDraft($input: CreateOrUpdateVodConcertDraftInput!) {
    createOrUpdateVodConcertDraft(input: $input) {
      result {
        ...VodConcertData
      }
    }
  }
  ${VodConcertDataFragmentDoc}
`
export type SaveVodConcertDraftMutationFn = Apollo.MutationFunction<
  Types.SaveVodConcertDraftMutation,
  Types.SaveVodConcertDraftMutationVariables
>

/**
 * __useSaveVodConcertDraftMutation__
 *
 * To run a mutation, you first call `useSaveVodConcertDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveVodConcertDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveVodConcertDraftMutation, { data, loading, error }] = useSaveVodConcertDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveVodConcertDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SaveVodConcertDraftMutation,
    Types.SaveVodConcertDraftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.SaveVodConcertDraftMutation, Types.SaveVodConcertDraftMutationVariables>(
    SaveVodConcertDraftDocument,
    options,
  )
}
export type SaveVodConcertDraftMutationHookResult = ReturnType<typeof useSaveVodConcertDraftMutation>
export type SaveVodConcertDraftMutationResult = Apollo.MutationResult<Types.SaveVodConcertDraftMutation>
export type SaveVodConcertDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.SaveVodConcertDraftMutation,
  Types.SaveVodConcertDraftMutationVariables
>
export const PublishVodConcertDocument = gql`
  mutation publishVodConcert($input: PublishVodConcertInput!) {
    publishVodConcert(input: $input) {
      errors {
        ...AdminApiError
      }
      result {
        ...VodConcertData
      }
    }
  }
  ${AdminApiErrorFragmentDoc}
  ${VodConcertDataFragmentDoc}
`
export type PublishVodConcertMutationFn = Apollo.MutationFunction<
  Types.PublishVodConcertMutation,
  Types.PublishVodConcertMutationVariables
>

/**
 * __usePublishVodConcertMutation__
 *
 * To run a mutation, you first call `usePublishVodConcertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishVodConcertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishVodConcertMutation, { data, loading, error }] = usePublishVodConcertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishVodConcertMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.PublishVodConcertMutation, Types.PublishVodConcertMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.PublishVodConcertMutation, Types.PublishVodConcertMutationVariables>(
    PublishVodConcertDocument,
    options,
  )
}
export type PublishVodConcertMutationHookResult = ReturnType<typeof usePublishVodConcertMutation>
export type PublishVodConcertMutationResult = Apollo.MutationResult<Types.PublishVodConcertMutation>
export type PublishVodConcertMutationOptions = Apollo.BaseMutationOptions<
  Types.PublishVodConcertMutation,
  Types.PublishVodConcertMutationVariables
>
export const UnpublishVodConcertDocument = gql`
  mutation unpublishVodConcert($input: UnpublishVodConcertInput!) {
    unpublishVodConcert(input: $input) {
      errors {
        ...AdminApiError
      }
      result {
        ...VodConcertData
      }
    }
  }
  ${AdminApiErrorFragmentDoc}
  ${VodConcertDataFragmentDoc}
`
export type UnpublishVodConcertMutationFn = Apollo.MutationFunction<
  Types.UnpublishVodConcertMutation,
  Types.UnpublishVodConcertMutationVariables
>

/**
 * __useUnpublishVodConcertMutation__
 *
 * To run a mutation, you first call `useUnpublishVodConcertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishVodConcertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishVodConcertMutation, { data, loading, error }] = useUnpublishVodConcertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpublishVodConcertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UnpublishVodConcertMutation,
    Types.UnpublishVodConcertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.UnpublishVodConcertMutation, Types.UnpublishVodConcertMutationVariables>(
    UnpublishVodConcertDocument,
    options,
  )
}
export type UnpublishVodConcertMutationHookResult = ReturnType<typeof useUnpublishVodConcertMutation>
export type UnpublishVodConcertMutationResult = Apollo.MutationResult<Types.UnpublishVodConcertMutation>
export type UnpublishVodConcertMutationOptions = Apollo.BaseMutationOptions<
  Types.UnpublishVodConcertMutation,
  Types.UnpublishVodConcertMutationVariables
>
export const DeleteVodConcertDraftDocument = gql`
  mutation deleteVodConcertDraft($input: DeleteVodConcertDraftInput!) {
    deleteVodConcertDraft(input: $input) {
      errors {
        ...AdminApiError
      }
      result {
        ...VodConcertData
      }
    }
  }
  ${AdminApiErrorFragmentDoc}
  ${VodConcertDataFragmentDoc}
`
export type DeleteVodConcertDraftMutationFn = Apollo.MutationFunction<
  Types.DeleteVodConcertDraftMutation,
  Types.DeleteVodConcertDraftMutationVariables
>

/**
 * __useDeleteVodConcertDraftMutation__
 *
 * To run a mutation, you first call `useDeleteVodConcertDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVodConcertDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVodConcertDraftMutation, { data, loading, error }] = useDeleteVodConcertDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVodConcertDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteVodConcertDraftMutation,
    Types.DeleteVodConcertDraftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.DeleteVodConcertDraftMutation, Types.DeleteVodConcertDraftMutationVariables>(
    DeleteVodConcertDraftDocument,
    options,
  )
}
export type DeleteVodConcertDraftMutationHookResult = ReturnType<typeof useDeleteVodConcertDraftMutation>
export type DeleteVodConcertDraftMutationResult = Apollo.MutationResult<Types.DeleteVodConcertDraftMutation>
export type DeleteVodConcertDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteVodConcertDraftMutation,
  Types.DeleteVodConcertDraftMutationVariables
>

import type { ComponentPropsWithRef } from 'react'

import { clsx } from 'clsx'
import { forwardRef } from 'react'

import { forms } from './_tokens'

export type LabelElementProps = ComponentPropsWithRef<'label'>

export const classes = {
  base: forms.label,
}

export const LabelElement = forwardRef<HTMLLabelElement, LabelElementProps>(function LabelElement(
  { children, className, ...rest }: LabelElementProps,
  reference,
) {
  return (
    <label className={clsx(classes.base, className)} ref={reference} {...rest}>
      {children}
    </label>
  )
})

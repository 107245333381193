import type { ComponentPropsWithoutRef } from 'react'

import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

/**
 * Creates DOM element to be used as React root.
 */
function createRootElement(id: string) {
  const rootContainer = document.createElement('div')
  rootContainer.setAttribute('id', id)
  return rootContainer
}

/**
 * Appends element as last child of body.
 */
function addRootElement(rootElement: HTMLElement) {
  const target = document?.body?.lastElementChild?.nextElementSibling
  if (target) {
    document.body.insertBefore(rootElement, target)
  }
}

/**
 * Hook to create a React Portal.
 * Automatically handles creating and tearing-down the root elements (no SRR
 * makes this trivial), so there is no need to ensure the parent target already
 * exists.
 * @example
 * const target = usePortal(id, [id]);
 * return createPortal(children, target);
 */
function usePortal(id: string, classes?: string) {
  const rootElemRef = useRef<HTMLElement | null>(null)
  const didCreateParent = useRef<boolean>(false)

  useEffect(
    function setupElement() {
      const existingParent: HTMLElement | null = document.querySelector(`#${id}`)
      const parentElement = existingParent ?? createRootElement(id)

      if (!existingParent) {
        addRootElement(parentElement)
        didCreateParent.current = true
      }

      // Add the detached element to the parent
      parentElement.append(rootElemRef.current as Node)

      return function removeElement() {
        rootElemRef.current?.remove()
        if (didCreateParent.current === true && !parentElement.childElementCount) {
          parentElement.remove()
        }
      }
    },
    [id],
  )

  function getRootElement() {
    if (!rootElemRef.current) {
      const element = document.createElement('div')
      if (classes) {
        element.classList.add(...classes.split(' '))
      }
      rootElemRef.current = element
    }
    return rootElemRef.current
  }

  return getRootElement()
}

type PortalProps = {
  id: string
} & ComponentPropsWithoutRef<'div'>

export function Portal({ children, className, id }: PortalProps) {
  const target = usePortal(id, className)
  return createPortal(children, target)
}

export { usePortal }

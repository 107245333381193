import { useEffect, useRef, useState } from 'react'

/**
 * Detects if a node targeted by `ref`, that has `position:sticky`, is in its "sticky"-state.
 *
 * This is primarily used for styling.
 *
 * For this to work the targeted element needs to be positioned just outside of the viewport as long as it is in
 * it's sticky-mode (eg. by setting `bottom: -1px`). When the element switches to its non-sticky mode, it will be
 * completely visible in the viewport (with a `threshold` of `1` = visible to 100%), which will fire the
 * IntersectionObserver and flip the state.
 *
 * @example
 * export function FormActionsPortalRoot() {
 *  const { ref, isSticky } = useIsSticky()
 *    return (
 *          <div
 *            ref={ref}
 *           className={clsx('sticky duration-200 transition w-full', {
 *             'bg-gray-50 shadow-up pb-px': isSticky,
 *           })}
 *         style={{ bottom: '-1px' }}
 *         />
 *     )
 *   }
 * @see https://github.com/trsc/dg_stage_admin_frontend/pull/73#discussion_r617326004
 */
export function useIsSticky() {
  const [isSticky, setIsSticky] = useState(false)
  // ? This is not very generic if you need it for other elements than div pass ref
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const cachedRef = ref.current

    // * All targeted browsers support `IntersectionObserver`
    // * This is mainly for tests as JSDOM does not support it
    const observer =
      window &&
      'IntersectionObserver' in window &&
      new IntersectionObserver(([entry]) => setIsSticky(entry.intersectionRatio < 1), { threshold: [1] })

    if (cachedRef && observer) {
      observer.observe(cachedRef)
    }

    return () => {
      if (cachedRef && observer) {
        observer.unobserve(cachedRef)
      }
    }
  }, [])

  return { isSticky, ref }
}

import type { DraftEditorFragment, Maybe, Scalars } from 'generated/types'
import type { ComponentPropsWithRef, ReactNode } from 'react'

import { clsx } from 'clsx'
import { PublicationStatus } from 'generated/types'
import { match } from 'ts-pattern'

import { toKebabCase } from 'utils/formatters/change-case'
import { formatTimeAgo } from 'utils/formatters/relative-date-time'

import type { BadgeElementProps } from './badge'
import type { BreadcrumbItemProps } from './breadcrumbs'

import { BadgeElement } from './badge'
import { Breadcrumb, BreadcrumbItem } from './breadcrumbs'

export type HeaderMetaInfo = {
  label: string
  value: string
}

type TitleBarElementProps = {
  actions?: ReactNode
  breadcrumbs?: BreadcrumbItemProps[]
  children?: ReactNode
  editor?: Maybe<DraftEditorFragment>
  metaInfos?: HeaderMetaInfo[]
  publicationStatus?: PublicationStatus
  title: string
  updatedAt?: Maybe<Scalars['NaiveDateTime']['output']>
} & ComponentPropsWithRef<'div'>

export function TitleBarElement({
  actions,
  breadcrumbs,
  children,
  editor,
  metaInfos,
  publicationStatus,
  title,
  updatedAt,
  ...props
}: TitleBarElementProps) {
  const lastEditedInfo =
    updatedAt && editor?.name ? `Last Saved ${formatTimeAgo(updatedAt)} by ${editor?.name}` : undefined

  return (
    <div {...props}>
      <header>
        <div className="w-full lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            {breadcrumbs ? (
              <Breadcrumb>
                {breadcrumbs.map((item, index, all) => (
                  <BreadcrumbItem key={index} {...item} isLast={index === all.length - 1} />
                ))}
              </Breadcrumb>
            ) : null}
            <div className={clsx('flex items-center', { 'mt-1': breadcrumbs })}>
              {publicationStatus ? (
                <BadgeElement
                  className="mr-2 mt-px cursor-pointer"
                  color={match<PublicationStatus, BadgeElementProps['color']>(publicationStatus)
                    .with(PublicationStatus.PublishedWithDraft, () => 'pink')
                    .with(PublicationStatus.Published, () => 'green')
                    .otherwise(() => 'gray')}
                  data-testid="form-actions-publication-status"
                  title={lastEditedInfo}
                >
                  {match(publicationStatus)
                    .with(PublicationStatus.PublishedWithDraft, () => 'Changed')
                    .with(PublicationStatus.Published, () => 'Published')
                    .with(PublicationStatus.Unpublished, () => 'Unpublished')
                    .otherwise(() => '')}
                </BadgeElement>
              ) : null}
              <h2
                className="text-lg font-bold leading-7 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight"
                data-testid="form-actions-title"
              >
                {title}
              </h2>
            </div>
          </div>
          {actions ? <div className="mt-5 flex items-center space-x-2 lg:ml-4 lg:mt-0">{actions}</div> : null}
        </div>
        {metaInfos ? (
          <div
            className="mt-2 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6"
            data-testid="form-actions-meta"
          >
            {metaInfos?.map((info) => (
              <BadgeElement
                className="mt-2"
                data-testid={`form-actions-meta-${toKebabCase(info.label)}`}
                key={`${info.label}`}
              >
                <span className="font-bold">{info.label}: &nbsp;</span>
                {info.value}
              </BadgeElement>
            ))}
          </div>
        ) : null}
      </header>
      {children ?? null}
    </div>
  )
}

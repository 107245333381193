/* eslint-disable @typescript-eslint/no-misused-promises */
import type { PublicationStatus } from 'generated/types'
import type { ComponentPropsWithoutRef, PropsWithChildren } from 'react'
import type {
  DefaultValues,
  FieldError,
  FieldValues,
  Path,
  UseFormProps,
  UseFormReset,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form'
import type { z } from 'zod'

import { DevTool } from '@hookform/devtools'
import { zodResolver } from '@hookform/resolvers/zod'
import { clsx } from 'clsx'
import isEqual from 'fast-deep-equal'
import { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { pipe } from 'remeda'

import type { UnknownObject } from 'utils/ts/utility-types'

import { AdditionalFormContextProvider, useAugmentedFormContext } from 'hooks/use-augmented-form-context'
import { useWarnIfUnsavedChanges } from 'state/use-warn-unsaved-changes'
import { getTranslation } from 'utils/i18n/translate'
import { isNonEmptyObject } from 'utils/is-empty-object'
import { isNotNullish } from 'utils/ts/type-guards'

import { FORMS_TRANSLATION_KEY } from './config'

const DEVTOOLS_ENABLED = process.env.NEXT_PUBLIC_FORM_DEVTOOLS_ENABLED === 'true' ? true : false

export type FormHelper<TFormValues extends FieldValues> = {
  resetForm: UseFormReset<TFormValues>
  setFieldError: UseFormSetError<TFormValues>
  setValue: UseFormSetValue<TFormValues>
}
export const isFieldError = (error: unknown): error is FieldError => isNonEmptyObject(error) && 'type' in error

type FormWrapperProps<TFormValues extends FieldValues, TFormOutputValues extends UnknownObject = UnknownObject> = {
  className?: string
  id?: string
  onSubmit?: (outputValues: TFormOutputValues, formHelper: FormHelper<TFormOutputValues>) => Promise<unknown>
  serverErrors?: Record<keyof TFormValues, string>
  shouldWarnOnDirty: boolean
}

const t = getTranslation(FORMS_TRANSLATION_KEY)

function FormWrapper<TFormValues extends FieldValues, TFormOutputValues extends UnknownObject>({
  children,
  className,
  id,
  onSubmit,
  serverErrors,
  shouldWarnOnDirty,
}: PropsWithChildren<FormWrapperProps<TFormValues, TFormOutputValues>>) {
  useWarnIfUnsavedChanges(shouldWarnOnDirty)
  const { control, handleSubmit, setError } = useAugmentedFormContext()

  useEffect(() => {
    if (isNotNullish(serverErrors)) {
      for (const [field, error] of Object.entries(serverErrors)) {
        setError(field as Path<TFormValues>, { message: t(error, { field }), type: 'server' })
      }
    }
  }, [serverErrors, setError])

  return (
    <>
      {DEVTOOLS_ENABLED === true ? <DevTool control={control} placement="top-left" /> : null}

      <form
        className={className}
        id={id}
        onSubmit={
          isNotNullish(onSubmit)
            ? handleSubmit(
                onSubmit as (outputValues: UnknownObject, formHelper: FormHelper<UnknownObject>) => Promise<unknown>,
              )
            : undefined
        }
      >
        {children}
      </form>
    </>
  )
}

export type FormProps<
  TApiData extends UnknownObject,
  TFormValues extends FieldValues,
  TFormOutputValues extends UnknownObject,
> = {
  apiToFormValues?: (apiData: TApiData | undefined) => DefaultValues<TFormValues>
  className?: string
  formValuesToApi?: (formValues: TFormValues) => TFormOutputValues
  id?: string
  initialValues: TApiData | undefined
  mode?: UseFormProps['mode']
  /**
   * `onSubmit` is not the primary way to submit the form and does not need to be provided
   * instead there are click-handlers on form-action-buttons. The function can be provided together
   * with an `id` and a formId to `FormActions` in order to submit the form on enter
   * ? it might make sense to make this behavior the default if requested by the users
   */
  onSubmit?: (outputValues: TFormValues, formHelper: FormHelper<TFormValues>) => Promise<unknown>
  publicationStatus?: PublicationStatus
  reValidateMode?: UseFormProps['reValidateMode']
  serverErrors?: Record<string, string>
  shouldWarnOnDirty?: boolean

  // ! might be unexpected but the validationSchema does actually not depend on the form-values
  // ! as all form-values must be strings for compatibility with the input#value-attribute
  // ! but we still want to have more meaningful types for the form-values
  validationSchema: z.Schema<UnknownObject>
}

/**
 * Form Wrapper component that tries to handle most use cases within this app.
 *
 * If you supply `apiToFormValues`- and `formValuesToApi`-methods the values will be converted
 * on entry and exit of the form by them.
 *
 * If you pass `serverErrors` they will be set into the form components.
 *
 * If you find a use-case that is not handled the escape hatch is to use {@link https://react-hook-form.com/api} directly.
 */
export function Form<
  TApiData extends UnknownObject,
  TFormValues extends FieldValues,
  TFormOutputValues extends UnknownObject,
>({
  apiToFormValues,
  children,
  className,
  formValuesToApi,
  initialValues,
  mode = 'onTouched',
  publicationStatus,
  reValidateMode = 'onChange',
  serverErrors,
  shouldWarnOnDirty = true,
  validationSchema,
  ...props
}: PropsWithChildren<FormProps<TApiData, TFormValues, TFormOutputValues>>) {
  const [errors, setErrors] = useState(serverErrors)
  const resolvedInitialValues = useMemo(
    () => apiToFormValues?.(initialValues) ?? (initialValues as DefaultValues<TFormValues>),
    [initialValues, apiToFormValues],
  )

  const methods = useForm<TFormValues>({
    defaultValues: resolvedInitialValues,
    mode,
    // * raw:true makes sure that the underlying form values are not transformed
    // * strings are the only valid types for input#value and we explicitly convert those
    // * in the conversions that run before a form is created
    // * but this should only happen in the validation-library
    resolver: zodResolver(validationSchema, undefined, { raw: true }),

    // * we still need to cast those strings into more complex types for validations
    reValidateMode,
  })

  // * Avoid setting serverErrors repeatedly when another action was performed
  // * eg. errors are from publish, but last performed action was saveDraft
  if (!isEqual(serverErrors, errors)) {
    setErrors(serverErrors)
  }

  // * we add our own form context to the one from react-hook-form for functionality not natively provided
  // * eg. tracking if an upload is currently in progress so form-submission can be prevented
  // * the contexts are bundled by the `useAugmentedForm`-hook
  const additionalContextValues = useMemo(
    () => ({
      /**
       * Augment react-hook-form's `handleSubmit`-method to
       *    - transform the form-values into the api-values if client-side validations have succeeded
       *    - provide some form-helpers to the call-side in order to reset the form to not trigger the dirty-check
       */
      handleSubmit: (
        onSubmit: (outputValues: TFormOutputValues, formHelper: FormHelper<TFormValues>) => Promise<unknown>,
      ) => {
        const augmentedSubmitHandler = (values: TFormValues) =>
          pipe(
            values,
            (values) => formValuesToApi?.(values) ?? (values as unknown as TFormOutputValues),
            (outputValues: TFormOutputValues) =>
              onSubmit(outputValues, {
                resetForm: methods.reset,
                setFieldError: methods.setError,
                setValue: methods.setValue,
              }).then((result) => {
                // resets forms touched and dirty state after successful form submission
                // we keep the values so that the user can continue editing a draft
                //  we keep the errors in order to display them if they are not fixed
                // (eg. if you saved a draft (which cannot cause publication-errors), but did not change the field that caused
                // the publication error)
                // * note that this runs before the form library sets the `isSubmitted` and `submitCount` state-variables
                // * so they might not reflect the values as you are used to
                methods.reset(undefined, { keepErrors: true, keepValues: true })
                return result
              }),
          )
        return methods.handleSubmit(augmentedSubmitHandler)
      },
      nodeId: initialValues?.id as string | undefined,
      publicationStatus,
    }),
    [formValuesToApi, methods, publicationStatus, initialValues?.id],
  )

  return (
    <FormProvider {...methods}>
      <AdditionalFormContextProvider<TFormValues, TFormOutputValues> {...additionalContextValues}>
        <FormWrapper className={className} serverErrors={errors} shouldWarnOnDirty={shouldWarnOnDirty} {...props}>
          {children}
        </FormWrapper>
      </AdditionalFormContextProvider>
    </FormProvider>
  )
}

type NarrowFormContainerProps = ComponentPropsWithoutRef<'div'>

export function NarrowFormContainer({ children, className, ...props }: NarrowFormContainerProps) {
  return (
    <div className={clsx('mx-auto max-w-4xl pb-16', className)} {...props}>
      {children}
    </div>
  )
}

import type { DraftEditorFragment, Maybe, Scalars } from 'generated/types'
import type { ComponentPropsWithoutRef, ReactNode } from 'react'
import type { FieldValues } from 'react-hook-form'

import { clsx } from 'clsx'
import { PublicationStatus } from 'generated/types'
import { useMemo } from 'react'
import { match, P } from 'ts-pattern'

import type { BreadcrumbItemProps } from 'components/elements/breadcrumbs'
import type { HeaderMetaInfo } from 'components/elements/title-bar'
import type { ChangePublicationStatusMutation, PublishMutation, SaveDraftMutation } from 'utils/ts/draft-api-operations'
import type { UnknownObject } from 'utils/ts/utility-types'

import { Button } from 'components/elements/button'
import { SplitButton } from 'components/elements/split-button'
import { TitleBarElement } from 'components/elements/title-bar'
import { useAugmentedFormContext } from 'hooks/use-augmented-form-context'
import { useIsSticky } from 'hooks/use-is-sticky'
import { Portal } from 'hooks/use-portal'
import { isBrowser } from 'utils/environment-guard'
import { FormActionMessages } from 'utils/forms/messages'
import { getTranslation } from 'utils/i18n/translate'
import { getTranslationKeyFormatter } from 'utils/i18n/translation-key-formatter'
import { isNotNullish } from 'utils/ts/type-guards'
import { getWebsiteLink } from 'utils/website-links/get-website-link'

import { FORMS_TRANSLATION_KEY } from './config'
import { NarrowFormContainer } from './form'

const t = getTranslation(FORMS_TRANSLATION_KEY)
const tk = getTranslationKeyFormatter('form_actions_header')

/**
 * Portal root on top of the page to position `FormActionsHeader`
 */
export function FormActionsPortalRoot() {
  const { isSticky, ref } = useIsSticky()
  return (
    <>
      <div
        className={clsx('sticky top-[-1px] z-20 w-full transition duration-200', {
          'border-t border-gray-300': !isSticky,
          'shadow-md': isSticky,
        })}
        id="form-action-bar"
        ref={ref}
      />
    </>
  )
}

type FormActionsHeaderProps<TFormOutputValues = UnknownObject, TFormValues extends FieldValues = UnknownObject> = {
  additionalActions?: Array<ReactNode>
  breadcrumbs?: BreadcrumbItemProps[]
  deleteDraftMutation?: ChangePublicationStatusMutation
  disableActions?: boolean
  editor?: Maybe<DraftEditorFragment>
  headerTitle: Maybe<string>

  metaInfos?: HeaderMetaInfo[]

  publicationStatus?: PublicationStatus
  publishMutation: PublishMutation<TFormOutputValues, TFormValues>
  saveDraftMutation: SaveDraftMutation<TFormOutputValues, TFormValues>
  unpublishMutation?: ChangePublicationStatusMutation

  updatedAt?: Maybe<Scalars['NaiveDateTime']['output']>
} & ComponentPropsWithoutRef<'div'>

const getActionsFromPublicationStatus = (
  nodeId: Scalars['ID']['output'],
  publicationStatus: PublicationStatus,
  unpublishMutation?: ChangePublicationStatusMutation,
  deleteDraftMutation?: ChangePublicationStatusMutation,
  breadcrumbs?: BreadcrumbItemProps[],
) =>
  match([publicationStatus, unpublishMutation, deleteDraftMutation] as const)
    .with([PublicationStatus.Published, P.not(P.nullish), P.any], ([, unpublish]) => [
      <SplitButton.Item
        key="unpublish"
        onClick={() => {
          void unpublish(nodeId)
        }}
      >
        {t(tk('unpublish', 'label'), 'Unpublish')}
      </SplitButton.Item>,
    ])
    .with(
      [PublicationStatus.Unpublished, P.any, P.not(P.nullish)],
      [PublicationStatus.PublishedWithDraft, P.any, P.not(P.nullish)],
      // eslint-disable-next-line unicorn/no-unreadable-array-destructuring
      ([, , deleteDraft]) => [
        <SplitButton.Item
          key="delete-draft"
          onClick={() => {
            void deleteDraft(nodeId).then((result) => {
              if (result.ok && publicationStatus === PublicationStatus.Unpublished) {
                if (breadcrumbs && breadcrumbs.length > 0) {
                  const target = breadcrumbs.at(-1)?.href
                  isBrowser() && target && window.location.assign(target)
                } else {
                  isBrowser() && window.history.back()
                }
              }
            })
          }}
        >
          {t(tk('delete_draft', 'label'), 'Delete Draft')}
        </SplitButton.Item>,
      ],
    )
    .otherwise(() => [])

/**
 * Renders a sticky form header that displays meta-information and actions that are possible with the entity
 * that is being edited. This is meant to be used with content-types that are draftable and thus have more complex
 * options then submitting the form. For these use regular submit buttons below the form-elements as it is a more
 * natural place for the user to expect the primary form action (e.g. a login-button below the email and password-fields)
 *
 * Needs to be nested in a `<Form />-Component as it accesses `handleSubmit` through context.
 *
 */
export function FormActionsHeader<
  TFormOutputValues extends FieldValues = UnknownObject,
  TFormValues extends FieldValues = UnknownObject,
>({
  additionalActions = [],
  breadcrumbs,
  children,
  deleteDraftMutation,
  disableActions = false,
  editor,
  headerTitle,
  metaInfos,
  publicationStatus,
  publishMutation,
  saveDraftMutation,
  unpublishMutation,
  updatedAt,
  ...props
}: FormActionsHeaderProps<TFormOutputValues, TFormValues>) {
  const { canSubmit, handleSubmit, isEditForm, nodeId } = useAugmentedFormContext<TFormOutputValues, TFormValues>()
  const submissionLabel = canSubmit ? undefined : t('Cannot submit form while file upload or submit is in progress.')
  const clientLink = useMemo(
    () =>
      isNotNullish(publicationStatus) && isNotNullish(nodeId) && publicationStatus !== PublicationStatus.Unpublished
        ? getWebsiteLink(nodeId)
        : undefined,
    [nodeId, publicationStatus],
  )

  return (
    <>
      <Portal id="form-action-bar">
        <TitleBarElement
          actions={
            <>
              <Button
                disabled={disableActions || !canSubmit}
                layout="secondary"
                name="saveDraft"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={handleSubmit(saveDraftMutation)}
                // ! does not work with void-return
                title={submissionLabel}
                type="submit"
              >
                {t(FormActionMessages.saveDraft)}
              </Button>
              <SplitButton
                // ! does not work with void-return
                disabled={disableActions || !canSubmit || !isEditForm}
                label={t(FormActionMessages.publish)}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={handleSubmit(publishMutation)}
                title={
                  isEditForm
                    ? submissionLabel
                    : t(tk('save_requirement', 'title'), 'You need to save a new item once before you can publish it.')
                }
              >
                {clientLink ? (
                  <SplitButton.Link href={clientLink} rel="noreferrer" target="_blank">
                    {t(tk('open_published_version', 'label'), 'Open published version')}
                  </SplitButton.Link>
                ) : null}
                {nodeId && publicationStatus
                  ? getActionsFromPublicationStatus(nodeId, publicationStatus, unpublishMutation, deleteDraftMutation)
                  : null}
                {additionalActions}
              </SplitButton>
            </>
          }
          breadcrumbs={breadcrumbs}
          className="border-b bg-white p-4 sm:px-6 lg:px-8"
          editor={editor}
          metaInfos={metaInfos}
          publicationStatus={publicationStatus}
          title={headerTitle ?? t(tk('title_fallback', 'label'), 'New Document')}
          updatedAt={updatedAt}
          {...props}
        />
      </Portal>
      <NarrowFormContainer className="pb-16">{children}</NarrowFormContainer>
    </>
  )
}

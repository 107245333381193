import type { ComponentPropsWithoutRef, MouseEventHandler, ReactElement } from 'react'

import { Popover, PopoverPanel, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { clsx } from 'clsx'
import { default as NextLink } from 'next/link'
import { cloneElement, Fragment } from 'react'

import { buttonDropdownItemTokens, buttonTokens } from './_tokens'

const classes = {
  base: [...buttonTokens.base, 'w-auto', 'select-none', 'focus:z-10', 'outline-0'],
  disabled: buttonTokens.disabled,
  primary: buttonTokens.primary,
  secondary: buttonTokens.secondary,
}

type SplitButtonProps = {
  disabled?: boolean
  label: string
  layout?: 'primary' | 'secondary'
  onClick: MouseEventHandler<HTMLButtonElement>
} & ComponentPropsWithoutRef<'div'>

function SplitButtonRoot({
  children,
  disabled = false,
  label,
  layout = 'primary',
  onClick,
  ...props
}: SplitButtonProps) {
  return (
    <Popover className="relative z-10">
      <div className="inline-flex" {...props}>
        <button
          className={clsx(classes.base, classes[layout], 'rounded-l-md', 'px-4', 'py-2', {
            'border-y border-l border-gray-300': layout === 'secondary',
            [classes.disabled]: disabled === true,
          })}
          disabled={disabled}
          onClick={(event) => !disabled && onClick(event)}
          type="button"
        >
          {label}
        </button>
        <div
          aria-hidden="true"
          className={clsx('w-px', { 'bg-blue-800': layout === 'primary', 'bg-gray-300': layout === 'secondary' })}
        ></div>
        <Popover.Button
          className={clsx(classes.base, classes[layout], 'rounded-r-md', 'px-2', {
            'border-y border-r border-gray-300': layout === 'secondary',
            [classes.disabled]: disabled === true,
          })}
        >
          <span className="sr-only">Show form actions</span>
          <ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
        </Popover.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <PopoverPanel className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg focus:outline-none">
          <div className="py-1" data-testid="split-button-menu">
            {children}
          </div>
        </PopoverPanel>
      </Transition>
    </Popover>
  )
}

export type ItemProps = {
  icon?: ReactElement
} & ComponentPropsWithoutRef<'button'>

function Item({ children, className, disabled = false, icon, onClick, ...props }: ItemProps) {
  return (
    <button
      className={clsx(
        buttonDropdownItemTokens.base,
        buttonDropdownItemTokens.hover,
        {
          [buttonDropdownItemTokens.disabled]: disabled,
          [buttonDropdownItemTokens.hasIcon]: !!icon,
          [buttonDropdownItemTokens.noIcon]: !icon,
        },
        className,
      )}
      disabled={disabled}
      onClick={(event) => {
        if (!disabled && onClick) {
          return onClick(event)
        }
      }}
      {...props}
    >
      {children}
    </button>
  )
}

export type LinkProps = {
  href: string
  icon?: ReactElement
} & ComponentPropsWithoutRef<'a'>

function Link({ children, href, icon, ...props }: LinkProps) {
  return (
    <NextLink
      className={clsx(buttonDropdownItemTokens.base, buttonDropdownItemTokens.hover, {
        [buttonDropdownItemTokens.hasIcon]: !!icon,
        [buttonDropdownItemTokens.noIcon]: !icon,
      })}
      href={href}
      {...props}
    >
      {icon
        ? cloneElement(icon, {
            'aria-hidden': true,
            className: buttonDropdownItemTokens.icon,
          })
        : null}
      {children}
    </NextLink>
  )
}

export const SplitButton = Object.assign(SplitButtonRoot, { Item, Link })

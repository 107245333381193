import type { ReactiveVar } from '@apollo/client'

import { makeVar } from '@apollo/client'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type StateVariable = [ReactiveVar<any>, any]

const registry: StateVariable[] = []

export const clearGlobalSharedState = () => {
  if (process.env.NODE_ENV !== 'test') {
    throw new Error('Clearing the global shared state MUST only be done in tests.')
  }

  for (const [stateVariable, initialValue] of registry) {
    stateVariable(initialValue)
  }
}

/**
 * Provides a small wrapper over Apollo Clients reactive-variables
 *
 * Currently used for reliably clearing global shared state in tests as state is kept on the level of ES modules
 * which will make it persist in-between test-cases if not cleared
 *
 */
export const makeStateVariable = <Type>(initialValue: Type): ReactiveVar<Type> => {
  const stateVariable = makeVar<Type>(initialValue)

  if (process.env.NODE_ENV !== 'test') {
    return stateVariable
  }

  registry.push([stateVariable, initialValue])
  return stateVariable
}

export { useReactiveVar as useStateVariable } from '@apollo/client'

import type { MutableRefObject } from 'react'

import { useEffect } from 'react'

/**
 * Execute code only if a click occurred outside of an element
 *
 * @example
 * function App() {
 *  const ref = useRef();
 *  const [isModalOpen, setModalOpen] = useState(false);
 *  useOnClickOutside(ref, () => setModalOpen(false));
 *
 * return (
 *   <div>
 *     {isModalOpen ? (
 *       <div ref={ref}>
 *         👋 Hey, I'm a modal. Click anywhere outside of me to close.
 *       </div>
 *     ) : (
 *       <button onClick={() => setModalOpen(true)}>Open Modal</button>
 *     )}
 *   </div>
 * );
 * }
 */
export function useOnClickOutside(reference: MutableRefObject<HTMLElement | null>, handler: (event: Event) => void) {
  useEffect(() => {
    const listener: EventListener = (event: Event) => {
      // * Do nothing if clicking ref's element or descendent elements
      if (!reference.current || reference.current.contains(event.target as Node)) {
        return
      }

      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [reference, handler])
}

import { NotificationElement } from 'components/elements/notification'
import { isBrowser } from 'utils/environment-guard'

export function ExceptionError() {
  return (
    <NotificationElement aria-label="Unexpected error" layout="error">
      Something went wrong! We do not know what. Please try to{' '}
      <a
        className="underline"
        href="#"
        onClick={(event) => {
          event.preventDefault()
          if (isBrowser()) {
            window.location.reload()
          }
        }}
      >
        reload
      </a>{' '}
      the page.
    </NotificationElement>
  )
}

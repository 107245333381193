import type { AdminUser, Scalars } from 'generated/types'

import type { DateInputProps } from 'components/form/date-input'
import type { DeepOmit, UnknownObject } from 'utils/ts/utility-types'

export const DEFAULT_LOCALE = 'en'
export const ADDITIONAL_LOCALES = ['de', 'ja'] as const
export const LOCALES = [DEFAULT_LOCALE, ...ADDITIONAL_LOCALES] as const
export type AllowedLocales = (typeof LOCALES)[number]

export type MetaData = {
  __typename?: string
  editor: Partial<AdminUser>
  insertedAt?: Scalars['NaiveDateTime']['output']
  updatedAt: Scalars['NaiveDateTime']['output']
}
export type MetaFields = keyof MetaData

export type WithoutMetaFields<T extends UnknownObject> = DeepOmit<T, MetaFields>

export function hasDatePicker(type: DateInputProps['type']) {
  const input = document.createElement('input')
  input.setAttribute('type', type)
  input.value = type === 'date' ? '2018-01-01' : '2021-03-18T10:55'
  return input.valueAsNumber != undefined && !Number.isNaN(input.valueAsNumber)
}

import { Role } from 'generated/types'

import type { RouteConfig } from 'utils/ts/shared-types'

import AssetDataIcon from 'public/assets/asset-data.svg'

export const ASSET_DATA_TRANSLATION_KEY = 'asset_data'

export const ASSET_DATA_ROUTES: RouteConfig = {
  index: {
    icon: AssetDataIcon,
    label: 'Asset Data',
    menuLabel: 'Asset Data',
    permissions: Role.Editor,
    uri: () => '/asset-data',
  },
}

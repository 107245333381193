import { UsersIcon } from '@heroicons/react/24/solid'
import { Role } from 'generated/types'

import type { RouteConfig } from 'utils/ts/shared-types'

export const ADMIN_USER_TRANSLATION_KEY = 'admin_user_management'

export const ADMIN_USER_ROUTES: RouteConfig<'create' | 'edit'> = {
  create: { label: 'Create new Admin User', permissions: Role.Superadmin, uri: () => '/admin-users/new' },
  edit: {
    label: 'Edit Admin User',
    permissions: Role.Superadmin,
    uri: (adminUserId: string) => `/admin-users/${adminUserId}`,
  },
  index: {
    icon: UsersIcon,
    label: 'Overview',
    menuLabel: 'Admin Users',
    permissions: Role.Superadmin,
    uri: () => '/admin-users',
  },
}

import type { ComponentPropsWithoutRef } from 'react'

import { clsx } from 'clsx'

import { toKebabCase } from 'utils/formatters/change-case'

type FieldsetElementProps = {
  title?: string
} & ComponentPropsWithoutRef<'fieldset'>

export function Fieldset({ children, className, title, ...props }: FieldsetElementProps) {
  return (
    <section
      className={clsx('mt-16 first-of-type:mt-4')}
      data-testid={title ? `fieldset-${toKebabCase(title)}` : undefined}
    >
      {title ? (
        <div className="relative mb-4">
          <div aria-hidden="true" className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-start">
            <span className="bg-gray-100 pr-3 text-lg font-medium text-gray-900">{title}</span>
          </div>
        </div>
      ) : null}
      <fieldset className={clsx('grid grid-cols-1 gap-x-4 gap-y-6', className)} {...props}>
        {children}
      </fieldset>
    </section>
  )
}

import type { ApolloError } from '@apollo/client'
import type { useAdminUserProfileLazyQuery } from 'generated/operations/login'
import type { AdminUser } from 'generated/types'

import { useAdminUserProfileQuery } from 'generated/operations/login'
import { useRouter } from 'next/router'

import { LOGIN_ROUTES } from 'features/login/config'
import { isNotNullish, isNullish } from 'utils/ts/type-guards'

export type AdminUserHookState = {
  adminUser: AdminUser | null
  error: ApolloError | null
  initial: boolean
  isAuthenticated: boolean
  loading: boolean
  refetch: ReturnType<typeof useAdminUserProfileLazyQuery>[1]['refetch']
}

/**
 * Hook to wrap the functionality of getting the currently logged in admin-user.
 * This is in an extra-hook because we do have some cross-cutting concerns that we want centralized. See code.
 *
 * Note that this hook is not a singleton. It is executed for each component that uses it. API-Calls are not duplicated
 * because of the Apollo-Cache that holds the info after the first invocation.
 */
export function useAdminUser(): AdminUserHookState {
  const router = useRouter()
  const { data, error, loading, refetch } = useAdminUserProfileQuery({
    skip: router.pathname.startsWith(LOGIN_ROUTES.index.uri()),
  })

  return {
    adminUser: data?.profile ?? null,
    error: error ?? null,
    initial: data === undefined && isNullish(error),
    isAuthenticated: isNotNullish(data),
    loading,
    refetch,
  }
}

import type { ComponentPropsWithoutRef } from 'react'

import { Bars3CenterLeftIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'

import { FormActionsPortalRoot } from 'components/form/form-actions-header'
import { NotificationContainer } from 'features/navigation/notification-container'
import { useAdminUser } from 'hooks/use-admin-user'

import { MenuMobile, MenuSidebar } from './menu'
import { ProfileMenu } from './profile-menu'

type AppShellProps = ComponentPropsWithoutRef<'div'>

export function AppShell({ children }: AppShellProps) {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const { isAuthenticated } = useAdminUser()

  return (
    <div className="flex h-screen overflow-hidden bg-gray-100 font-sans antialiased">
      <MenuMobile isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />

      <MenuSidebar />

      <div className="flex-1 overflow-auto focus:outline-none">
        <div className="relative z-30 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white lg:border-none">
          {isAuthenticated ? (
            <button
              className="border-r border-gray-200 px-4 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 lg:hidden"
              onClick={() => setMenuOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3CenterLeftIcon className="h-6 w-6" />
            </button>
          ) : null}
          <div className="flex flex-1 justify-between px-4 sm:px-6 lg:px-8">
            <div className="flex flex-1">{/* Searchbar */}</div>
            <ProfileMenu />
          </div>
        </div>
        <FormActionsPortalRoot />
        <NotificationContainer />
        <main className="relative flex-1">
          <div className="mx-auto max-w-6xl px-4 py-8 sm:px-6 lg:px-8">{children}</div>
        </main>
      </div>
    </div>
  )
}

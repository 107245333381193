import { Transition } from '@headlessui/react'
import { cloneElement, isValidElement } from 'react'

import { useNotifications } from 'state/use-notifications'

export function NotificationContainer() {
  const { isVisible, notification } = useNotifications()

  return (
    <div className="pointer-events-none fixed left-0 top-0 z-30 w-screen">
      <Transition
        as={'div'}
        className="mx-auto flex justify-center px-4 py-1.5 sm:px-6 lg:px-8"
        enter="transform ease-out duration-300 transition"
        enterFrom="-translate-y-2 opacity-0"
        enterTo="translate-y-0 opacity-100"
        leave="transition ease-in duration-100"
        leaveFrom="translate-y-0 opacity-100"
        leaveTo="-translate-y-2 opacity-0"
        show={isVisible}
      >
        {isValidElement(notification) &&
          cloneElement(notification, {
            'aria-label': 'User Action Feedback',
            className: 'pointer-events-auto max-w-prose z-10',
          })}
      </Transition>
    </div>
  )
}

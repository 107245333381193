import type { ReactElement } from 'react'

import type { NotificationElementProps } from 'components/elements/notification'

import { makeStateVariable, useStateVariable } from 'utils/state/state'

const setIsVisible = makeStateVariable(false)
const setNotification = makeStateVariable<ReactElement<NotificationElementProps> | undefined>(undefined)

let timeoutRef: ReturnType<typeof setTimeout>

export const showNotification = (
  nextNotification: ReactElement<NotificationElementProps>,
  hideTimeout = 5000,
): void => {
  if (timeoutRef) {
    clearTimeout(timeoutRef)
  }
  setIsVisible(true)
  setNotification(nextNotification)

  if (hideTimeout > 0) {
    timeoutRef = setTimeout(() => setIsVisible(false), hideTimeout)
  }
}

const hideNotification = (): void => {
  setIsVisible(false)
}

export const useNotifications = () => {
  const isVisible = useStateVariable(setIsVisible)
  const notification = useStateVariable(setNotification)

  return {
    hideNotification,
    isVisible,
    notification,
    showNotification,
  }
}

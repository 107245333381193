import type { NormalizedCacheObject } from '@apollo/client'
import type { AppProps } from 'next/app'

import { ApolloProvider } from '@apollo/client'
import dynamic from 'next/dynamic'

import type { RoutePermission } from 'utils/ts/shared-types'

import { ModalShim } from 'components/elements/modal/modal-shim'
import { ErrorBoundary } from 'features/error-boundary/error-boundary'
import { AppShell } from 'features/navigation/app-shell'
import { APOLLO_STATE_PROP_NAME, useApollo } from 'utils/apollo/client'
import { initializeSearchClient } from 'utils/search/algolia'

import 'flag-icons/css/flag-icons.min.css'
import 'styles/globals.css'

initializeSearchClient()

export type AppPage = {
  pageProps: {
    [APOLLO_STATE_PROP_NAME]?: NormalizedCacheObject
    minimumRequiredRole?: RoutePermission
  }
} & Omit<AppProps, 'pageProps'>

// * execute most of the app only on the client to avoid problems with SSR by leveraging dynamic imports
// * @see https://nextjs.org/docs/advanced-features/dynamic-import
const RoutePermissionGuard = dynamic(
  () => import('../features/navigation/route-permissions-guard').then((module_) => module_.RoutePermissionGuard),
  { ssr: false },
)

function App({ Component, pageProps }: AppPage) {
  const { [APOLLO_STATE_PROP_NAME]: apolloCache, minimumRequiredRole, ...props } = pageProps
  const apolloClient = useApollo(apolloCache)

  return (
    <ApolloProvider client={apolloClient}>
      <AppShell>
        <ErrorBoundary>
          <RoutePermissionGuard minimumRequiredRole={minimumRequiredRole}>
            <Component {...props} />
          </RoutePermissionGuard>
        </ErrorBoundary>
      </AppShell>
      <ModalShim />
    </ApolloProvider>
  )
}

export default App

/* eslint-disable @typescript-eslint/no-misused-promises */
import type { ComponentPropsWithoutRef, ReactNode } from 'react'

import type { FormSubmitHandler } from 'hooks/use-augmented-form-context'

import { useAugmentedFormContext } from 'hooks/use-augmented-form-context'

type FormActionsFooterProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: ({ handleSubmit }: { handleSubmit: FormSubmitHandler<any, any> }) => ReactNode
}
/**
 * Footer for submit or other actions for display below the form. Remains in regular document-flow.
 *
 * Intended to be used with short forms where scrolling will not hide the form-actions (eg. login-form, importer-upload)
 */
export function FormActionsFooter({ children }: FormActionsFooterProps) {
  const { handleSubmit } = useAugmentedFormContext()
  return <FormActionsFooterLayout>{children({ handleSubmit })}</FormActionsFooterLayout>
}

type FormActionsFooterLayoutProps = ComponentPropsWithoutRef<'footer'>

export function FormActionsFooterLayout({ children, ...props }: FormActionsFooterLayoutProps) {
  return (
    <footer className="mt-8 flex flex-1 justify-between space-x-4 lg:justify-end" {...props}>
      {children}
    </footer>
  )
}

import type { ComponentPropsWithoutRef } from 'react'

import { clsx } from 'clsx'

export type BadgeElementProps = {
  color?: 'blue' | 'gray' | 'green' | 'indigo' | 'pink' | 'purple' | 'red' | 'yellow'
} & ComponentPropsWithoutRef<'span'>

const classes = {
  base: ['inline-flex', 'items-center', 'px-2', 'py-0.5', 'rounded', 'text-xs', 'font-medium'],
  blue: 'bg-blue-100 text-blue-800',
  gray: 'bg-gray-100 text-gray-800',
  green: 'bg-green-100 text-green-800',
  indigo: 'bg-indigo-100 text-indigo-800',
  pink: 'bg-pink-100 text-pink-800',
  purple: 'bg-purple-100 text-purple-800',
  red: 'bg-red-100 text-red-800',
  yellow: 'bg-yellow-100 text-yellow-800',
}

/**
 * A pill-like badge that indicates a status of the object it is used alongside with, eg. a transcoding job
 *
 * @example
 * <BadgeElement color="green">Transcoded</BadgeElement>
 */
export function BadgeElement({ children, className, color = 'gray', ...props }: BadgeElementProps) {
  return (
    <span className={clsx(classes.base, classes[color], className)} {...props}>
      {children}
    </span>
  )
}

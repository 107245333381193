import type { ReactNode } from 'react'

import { Transition } from '@headlessui/react'
import { ChevronDownIcon, UserIcon } from '@heroicons/react/24/solid'
import { clsx } from 'clsx'
import { useMemo, useRef, useState } from 'react'

import { buttonTokens } from 'components/elements/_tokens'
import { useAdminUser } from 'hooks/use-admin-user'
import { useLogout } from 'hooks/use-logout'
import { useOnClickOutside } from 'hooks/use-on-click-outside'
import { t } from 'utils/i18n/translate'

const getInitials = (username: string | undefined) => {
  if (username === undefined) {
    return <UserIcon className="h-6 w-6 text-gray-700" />
  }

  return username
    .split(' ')
    .filter((_item, index, array) => index === 0 || index == array.length - 1)
    .map((part) => part.trim().slice(0, 1))
    .join('')
    .toUpperCase()
}

function Avatar({ children }: { children: ReactNode }) {
  return (
    <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-gray-200 font-bold text-gray-700">
      {children}
    </span>
  )
}

export function ProfileMenu() {
  const { adminUser, isAuthenticated } = useAdminUser()
  const { logout } = useLogout()
  const [isProfileMenuOpen, setProfileMenuOpen] = useState(false)

  const initials = useMemo(() => getInitials(adminUser?.name), [adminUser])
  const profileMenuRef = useRef<HTMLDivElement | null>(null)
  useOnClickOutside(profileMenuRef, () => setProfileMenuOpen(false))

  if (!isAuthenticated) {
    return <></>
  }

  return (
    <div className="ml-4 flex items-center md:ml-6" ref={profileMenuRef}>
      <div className="relative ml-3">
        <div>
          <button
            aria-haspopup="true"
            className={clsx(
              buttonTokens.focus,
              'flex max-w-xs items-center rounded-full bg-white text-sm lg:rounded-md lg:p-2 lg:hover:bg-gray-50',
            )}
            id="user-menu"
            onClick={() => setProfileMenuOpen((lastValue) => !lastValue)}
          >
            <Avatar>{initials}</Avatar>
            <span className="ml-3 hidden truncate text-sm font-medium text-gray-700 lg:block">
              <span className="sr-only">{t('Open user menu')} </span>
              {adminUser?.name}
            </span>
            <ChevronDownIcon className="ml-1 hidden h-5 w-5 flex-shrink-0 text-gray-400 lg:block" />
          </button>
        </div>
        <Transition
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          show={isProfileMenuOpen}
        >
          <div
            aria-labelledby="user-menu"
            aria-orientation="vertical"
            className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5"
            role="menu"
          >
            <a
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              href="/login"
              onClick={(event) => {
                event.preventDefault()
                setProfileMenuOpen(false)
                void logout()
              }}
              role="menuitem"
            >
              {t('Sign out')}
            </a>
          </div>
        </Transition>
      </div>
    </div>
  )
}

import type { RefinementCtx } from 'zod'

import { isDate } from 'remeda'
import { z } from 'zod'

import type { UnknownObject } from 'utils/ts/utility-types'

import { FORMS_TRANSLATION_KEY } from 'components/form/config'
import { BackendValidationErrors } from 'utils/graphql/errors'
import { t } from 'utils/i18n/translate'

import { FormValidationMessages } from './messages'

// * used for server-side errors, some of them should expand the additional locales so that the user
// * can see what causes the error
export const errorsThatExpandTranslations: string[] = [
  t(BackendValidationErrors.DefaultTranslationMissing, { ns: FORMS_TRANSLATION_KEY }),
]

export type FormValidationErrors<TFormFieldNames extends string> = Record<TFormFieldNames, string>

/**
 * Checks if the passed string conforms to an isrc-format or is an empty string.
 *
 * If you want to require the string you can use `isrcSchema().required()`
 */
export const isrcSchema = ({ required = false, required_error }: { required?: boolean; required_error?: string }) => {
  const isrc = requiredStringSchema({ message: required_error }).regex(/(^[A-Z]{2}-?\w{3}-?\d{2}-?\d{5}$)|^$/g, {
    message: t(FormValidationMessages.isrcFormat, { ns: FORMS_TRANSLATION_KEY }),
  })

  return required ? isrc : isrc.or(z.literal(''))
}

/**
 * Checks if the passed string is a valid upc (13 digits) or an empty string
 *
 * If you want to require the string you can use `upcSchema().required()`
 */
export const upcSchema = () =>
  z.string().regex(/(\d{13})|^$/, { message: t(FormValidationMessages.upcFormat, { ns: FORMS_TRANSLATION_KEY }) })

/**
 * Checks if the passed string is a valid timestamp for a mark or an empty string
 *
 * If you want to require the string you can use `markschema().required()`
 */
export const markSchema = ({
  preventZero = false,
  required = false,
  required_error,
}: {
  preventZero?: boolean
  required?: boolean
  required_error?: string
}) => {
  const stringSchema = required ? requiredStringSchema({ message: required_error }) : z.string()
  return stringSchema
    .regex(/(^\d\d:\d\d:\d\d$)|^$/, { message: t(FormValidationMessages.markFormat, { ns: FORMS_TRANSLATION_KEY }) })
    .refine((value) => !(preventZero && value === '00:00:00'), {
      message: t(FormValidationMessages.durationNotZero, { ns: FORMS_TRANSLATION_KEY }),
    })
}

/**
 * Checks if the passed value can be parsed into a valid date
 */
export const dateSchema = (required = false) => {
  const dateValidation = z.string().pipe(z.coerce.date())

  return required ? dateValidation : dateValidation.or(z.literal(''))
}

/**
 * Check if the passed value can be cast into a number that can fit further validation rules
 */
export const numberSchema = () => z.union([z.literal(''), z.string().pipe(z.coerce.number())])

/**
 * Check if the passed values is non-empty string
 */
export const requiredStringSchema = ({ message }: { message?: string } = {}) =>
  z
    .string()
    .trim()
    .min(1, { message: message ?? t(FormValidationMessages.requiredField, { ns: FORMS_TRANSLATION_KEY }) })

export const yearSchema = () =>
  z.coerce.string().regex(/^\d{4}$/, { message: t(FormValidationMessages.yearFormat, { ns: FORMS_TRANSLATION_KEY }) })

type DateValidatorOptions = {
  field: string
  message: string
  ref: string
}

export const afterDateValidator =
  <T extends UnknownObject>({ field, message, ref }: DateValidatorOptions) =>
  ({ [field]: target, [ref]: compare }: T, ctx: RefinementCtx) => {
    if (isDate(compare) && isDate(target) && compare > target) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message,
        path: [field],
      })
    }
  }
